//@flow

import type { ValidationRules } from "./Validation";
import { UserOrganizationConfig, UserConfig } from './UserConfig';
import type { ErrorResult } from "./Validation";
import type { LangString } from './lib/Language';
export type WebSettings={
	uploadLimit: number;
};

export type Option={
	category?: string;
	value: string;
	label: string;
	description?: string;
};

export type LangOption={
	category?: LangString;
	value: string;
	label: LangString;
	description?: LangString;
	order?: number|null;
};

export type DbFileInfo={
	mimeType: string;
	name: string;
	size: number;
	added: string;
};

export type ImportType = 'CSV'|'XLSX';
export type ImportResult={
	type: ImportType;
	sep: string;
	quote: string;
	columns: { [string]: number|null };
	missingColumns: Array<string>;
	data: Array<{}>;
};

export type TableResult<T> = {
	items: number;
	data: Array<T>;
};

export type AgreementInfo={
	name: string;
	group: LangString;
	info: LangString;
	required: boolean;
	order: number;
};

export type RegisterCompanyInfo={
	name: string;
	postalCode: string;
	city: string;
	address: string;
	country: string;
};

export const RegisterCompanyInfoValidation: ValidationRules = {
	name: { notEmpty: true, },
	postalCode: { notEmpty: true, },
	city: { notEmpty: true, },
	address: { notEmpty: true, },
	country: { notEmpty: true, },
};

export const RegisterCompanyInfoInitial: RegisterCompanyInfo = {
	name: "",
	postalCode: "",
	city: "",
	address: "",
	country: "",
};

export type NIP={
	id: string;
	country: string;
	nip: string;
};

export type UOStatus = 'Rejected'|'New'|'Active'|'TemporaryBlocked'|'Blocked';
export type InternalServices = 'Vindication'|'GlobalVindication'|'Monitoring'|'DifficultDebts'|'DebtExchange'|'InvoiceMonitoring';
export type Language = 'pl'|'en';
export type FieldFilter={
	field: string;
	value: any;
};

export type AccessRight={
	id: string|null;
	ident: string;
	typeId: string;
	type: InternalServices|null;
	name: LangString;
	desc: LangString;
	settings: { [string]: any }|null;
	order: number;
	show: { [Language]: boolean|null };
	filter: Array<FieldFilter>|null;
	read: boolean;
	write: boolean;
	limit: number|null;
	prices: LangString;
};

export type UserRight = 'Admin';
export type UserOrganizationData={
	id: string;
	userId: string;
	name: string;
	email: string;
	orgId: string;
	org: string;
	orgTax: string;
	mode: boolean|null;
	status: UOStatus;
	services: Array<AccessRight>;
	rights: Array<UserRight>;
};

export type UserEdit={
	id: string;
	email: string;
	name: string;
	phone: string;
	organizations: Array<UserOrganizationData>;
};

export const UserEditValidation: ValidationRules = {
	id: { },
	email: { notEmpty: true, },
	name: { notEmpty: true, },
	phone: { },
	organizations: { },
};

export const UserEditInitial: UserEdit = {
	id: "",
	email: "",
	name: "",
	phone: "",
	organizations: [],
};

export type VindicationFile={
	dbId: string;
	customName: string;
};

export type VindicationPaymentRegistration={
	amount: string;
	currency: string;
	date: string;
	description: string;
};

export type MonitoringScenarioChangeMode = 'None'|'Blank'|'Force';
export type InvoiceMonitoringPacketType = 'Free'|'Mini'|'Midi'|'Maxi'|'Corporate'|'ExtraSms'|'ExtraMail'|'ExtraSmsMail';
export type UserPassword={
	oldPassword: string;
	newPassword: string;
	repeatPassword: string;
	passwordVisible: boolean;
};

export const UserPasswordValidation: ValidationRules = {
	oldPassword: { notEmpty: true, },
	newPassword: { },
	repeatPassword: { },
	passwordVisible: { },
};

export const UserPasswordInitial: UserPassword = {
	oldPassword: "",
	newPassword: "",
	repeatPassword: "",
	passwordVisible: false,
};

export type DebtorQueryInfo={
	tax: string;
	pesel: string;
	name: string;
	debts: number|null;
};

export type ReceivableInfo={
	number: string;
	sell: string;
	pay: string;
};

export type ApiKeyRole = 'InvoiceMonitoringDataIntegrator'|'Monitoring';
export type TableQuery={
	sort: string|null;
	items: number;
	from: number;
	filters: Array<FieldFilter>|null;
	query: string|null;
};

export type FileInfo={
	safeId: string;
	name: string;
};

export type CompanyInfo={
	id: string;
	name: string;
	tax: string;
	vindications: number;
	users: number;
};

export type CompanyApiKey={
	role: ApiKeyRole;
	key: string;
};

export type OrderStatus = 'Grace'|'Ordered'|'InProgress'|'Rejected'|'Done';
export type OrderInfo={
	id: string;
	type: LangString;
	name: string;
	tax: string;
	added: string;
	status: OrderStatus;
};

export type VindicationTableInfo={
	initialCapital: string;
	initialInterests: string;
	initialCosts: string;
	actualCapital: string;
	actualInterests: string;
	actualCosts: string;
	recoveredCapital: string;
	recoveredInterests: string;
	recoveredCosts: string;
	id: string;
	fullId: string;
	added: string;
	ecId: string;
	tax: string;
	debtor: string;
	extId: string;
	currency: string;
	changed: string;
	stageChanged: string;
	status: OrderStatus;
	stage: string;
	ecStatus: string|null;
	amicable: boolean;
	judicial: boolean;
	domestic: boolean;
	chargeInterests: boolean;
	chargeCosts: boolean;
	invoiceMonitoring: boolean;
	invoiceMonitoringAutomatic: boolean;
	creditor?: string;
	/** Ext */
	client?: string;
	/** Ext */
	clientEcId?: string|null;
	/** Ext */
	clientFistName?: string;
	/** Ext */
	clientLastName?: string;
	/** Ext */
	clientTax?: string;
	/** Ext */
	rejectionReason?: LangString;
	/** Ext */
	acceptedBy?: string;
	/** Ext */
	worker?: string;
	/** Ext */
	changed2?: string;
	initial: string;
	actual: string;
	recovered: string;
};

export type DebtExchangeDoc={
	initialCapital: string;
	initialInterests: string;
	initialCosts: string;
	actualCapital: string;
	actualInterests: string;
	actualCosts: string;
	recoveredCapital: string;
	recoveredInterests: string;
	recoveredCosts: string;
	type: string;
	sellDate: string;
	paymentDate: string;
	initial: string;
	actual: string;
	recovered: string;
};

export type DebtExchangeInfo={
	id: string;
	date: string;
	name: string;
	tax: string;
	city: string;
	value: string;
	sellValue: string;
	actualCapital: string;
	recovered: string;
	actual: string;
	currency: string;
	risky: boolean;
};

export type DebtExchangeDetails={
	initialCapital: string;
	initialInterests: string;
	initialCosts: string;
	actualCapital: string;
	actualInterests: string;
	actualCosts: string;
	recoveredCapital: string;
	recoveredInterests: string;
	recoveredCosts: string;
	id: string;
	value: string;
	sellValue: string;
	date: string;
	claimDate: string;
	currency: string;
	name: string;
	tax: string;
	address: string;
	postalCode: string;
	city: string;
	comment: string;
	risky: boolean;
	debtorClaims: number;
	debtorJudicial: number;
	debtorAmicable: number;
	debtorTotal: string;
	debtorInitial: string;
	debtorInterests: string;
	debtorCosts: string;
	debtorRecovered: string;
	docs: Array<DebtExchangeDoc>;
	other: Array<DebtExchangeInfo>;
	initial: string;
	actual: string;
	recovered: string;
};

export type DebtsSummary={
	totalDebtsCount: number;
	riskyDebtsCount: number;
	debtsSum: string;
	cities: Array<string>;
	currencies: Array<string>;
};

export type DifficultDebtInfo={
	name: string;
	fileId: string;
	size: number;
	date: string;
};

export type IMSIGDataEntryInfo={
	date: string;
	msigDate: string;
	krzDate: string;
	extId: string;
	msigSignature: string;
	krzSignature: string;
	content: string;
};

export type IMSIGDataInfo={
	tax: string;
	pesel: string;
	entries: Array<IMSIGDataEntryInfo>;
};

export type InvoiceSynchronizationType = 'Reset'|'DataIntegrator'|'Import'|'wFirma'|'iFirma'|'Fakturownia'|'inFakt'|'SaldeoSmart';
export type InvoiceMonitoringIntegrationSettings={
	type: InvoiceSynchronizationType;
	key: string;
	key2: string;
	key3: string;
};

export type MonitoringScenarioStepSetup={
	email: boolean;
	sms: boolean;
};

export type MonitoringSettings={
	scenario: string;
	steps: Array<MonitoringScenarioStepSetup>;
	minimalBalance: string;
	pause: string;
	workDaysOnly: boolean;
	timeFrom: number;
	timeTo: number;
	email: string;
	phone: string;
	logo: string;
	debtCollection: boolean;
	amicable: boolean;
	stop: number|null;
	observe: boolean;
};

export type InvoiceMonitoringSubscriptionType = 'Free'|'Mini'|'Midi'|'Maxi'|'Corporate';
export type InvoiceMonitoringPacketPrice={
	type: InvoiceMonitoringPacketType;
	canBuy: boolean;
	price: string;
	priceGross: string;
	x3monthPrice: string;
	x3monthPriceGross: string;
	x6monthPrice: string;
	x6monthPriceGross: string;
	x12monthPrice: string;
	x12monthPriceGross: string;
	sms: number|null;
	email: number|null;
	smsPrice: string;
	emailPrice: string;
};

export type InvoiceMonitoringSubscription={
	subscription: InvoiceMonitoringSubscriptionType;
	term: string;
	sendEmails: number;
	sendSMSs: number;
	dom: number;
	extraEmails: number;
	extraSMSs: number;
	subscriptionEmails: number;
	subscriptionSMSs: number;
	prices: { [InvoiceMonitoringPacketType]: InvoiceMonitoringPacketPrice };
};

export type MonitoredEntry={
	id: string;
	nip: string;
	company: string;
	disabled: boolean;
	added: string;
	lastDebt: string;
	lastImsig: string;
	debts: number;
};

export type MonitoringStage = 'Settled'|'BeforePayment'|'SlightlyOutOfDate'|'Expired'|'HeavilyOverdue'|'ExceedingScenario'|'NoScenario'|'VindicationGrace'|'Vindication';
export type MonitoredCounter={
	sms: number;
	email: number;
};

export type MonitoredClientDetails={
	id: string;
	name: string;
	tax: string;
	balance: { [string]: string };
	scenario: string;
	scenarioLabel: LangString;
	stage: MonitoringStage;
	finalStage: MonitoringStage;
	overdue: number|null;
	phone: string;
	email: string;
	paused: boolean;
	validPhone: boolean;
	validEmail: boolean;
	vindication: boolean;
	monitoredInvoices: number;
	grace: boolean;
	excluded: boolean;
	address: string;
	city: string;
	postalCode: string;
	stages: { [MonitoringStage]: string };
	steps: Array<MonitoringScenarioStepSetup>;
	sent: Array<MonitoredCounter>;
	projected: Array<MonitoredCounter>;
	canVindicate: boolean;
};

export type InvoiceMonitoringInvoiceStatus = 'Paid'|'Disabled'|'Unpaid'|'Grace'|'Vindication';
export type MonitoredInvoice={
	id: string;
	clientId: string;
	number: string;
	client: string;
	tax: string;
	sellDate: string;
	payDate: string;
	amount: string;
	paid: string;
	left: string;
	overdue: number;
	stage: MonitoringStage;
	currency: string;
	status: InvoiceMonitoringInvoiceStatus;
	vRejected: boolean;
	vAborted: boolean;
};

export type InvoicesStats={
	amount: string;
	paid: string;
	unpaid: string;
	invoices: number;
	clients: number;
};

export type NotificationType = 'VindicationNew'|'VindicationPayment'|'VindicationFile'|'VindicationAction'|'VindicationAccepted'|'VindicationRejected'|'VindicationStage'|'VindicationReceivable'|'OrderNew'|'OrderAccepted'|'OrderRejected'|'OrderDone'|'MonitoredAdded'|'MonitoredRemoved'|'MonitoredDebt'|'MonitoredImsig'|'UserAdded'|'UserChanged'|'DebtExchangeBuy'|'OrganizationAccepted'|'IMPaymentFailed'|'IMOutOfSync'|'IMSubscriptionNearEnd'|'IMSubscriptionEnded'|'IMOutOfSMS'|'IMOutOfEmail'|'IMClientExceedingScenario'|'IMClientVindication'|'IMExtraChange'|'IMInvoiceOverdue';
export type UserWebNotification={
	time: string;
	uId?: string;
	type: NotificationType;
	service: InternalServices;
	typeId: string;
	message: string;
	url: string;
};

export type MonitoringDesktopInformation={
	type: InvoiceMonitoringSubscriptionType;
	autoVindication: boolean;
	autoVindicationGraceDays: number;
	periodSms: number;
	periodEmail: number;
	extraSms: number;
	extraEmail: number;
	lastSync: string;
	lastSyncDaysAgo: number|null;
	lastSyncDanger: boolean;
	clients: number;
	overdueClients: number;
	invoices: number;
	overdueInvoices: number;
	overdueAverageDays: number;
	efficiency: number|null;
	beforeStats: InvoicesStats;
	overdueStats: InvoicesStats;
	byStage: { [MonitoringStage]: InvoicesStats };
	byCurrency: { [string]: InvoicesStats };
	events: Array<UserWebNotification>;
	sent: Array<MonitoredCounter>;
	projected: Array<MonitoredCounter>;
};

export type MonitoringScenarioSteps={
	days: number;
	stage: MonitoringStage;
};

export type MonitoringScenarioDetails={
	id: string;
	days: number;
	title: LangString;
	desc: LangString;
	steps: Array<MonitoringScenarioSteps>;
};

export type PaymentStatus = 'Processing'|'Success'|'Failed';
export type VindicationDictionaries={
	cities: Array<string>;
	currencies: Array<string>;
	statuses: Array<string>;
	countries: Array<string>;
	tax: Array<string>;
	stages: Array<string>;
};

export type UserAgreementInfo={
	name: string;
	group: LangString;
	info: LangString;
	required: boolean;
	order: number;
	accepted: string;
	withdraw: string;
};

export type CompanyUser={
	uoId: string;
	id: string;
	name: string;
	email: string;
	lastLogged: string;
	status: UOStatus;
};

export type CompanyService={
	service: LangString;
	fileId: string;
	fileName: string;
};

export type UserCompany={
	id: string;
	name: string;
	nip: string;
	address: string;
	postalCode: string;
	city: string;
	country: string;
	lastLogged: string;
	packet: LangString;
	users: Array<CompanyUser>;
	services: Array<CompanyService>;
	notifications: Array<UserWebNotification>;
};

export const UserCompanyValidation: ValidationRules = {
	id: { notEmpty: true, },
	name: { notEmpty: true, },
	nip: { notEmpty: true, },
	address: { notEmpty: true, },
	postalCode: { notEmpty: true, },
	city: { notEmpty: true, },
	country: { notEmpty: true, },
	lastLogged: { },
	packet: { },
	users: { },
	services: { },
	notifications: { },
};

export const UserCompanyInitial: UserCompany = {
	id: "",
	name: "",
	nip: "",
	address: "",
	postalCode: "",
	city: "",
	country: "",
	lastLogged: "",
	packet: { pl: '', en: '', },
	users: [],
	services: [],
	notifications: [],
};

export type CompanyEdit={
	id: string;
	name: string;
	nip: string;
	address: string;
	postalCode: string;
	city: string;
	country: string;
};

export const CompanyEditValidation: ValidationRules = {
	id: { notEmpty: true, },
	name: { notEmpty: true, },
	nip: { notEmpty: true, },
	address: { notEmpty: true, },
	postalCode: { notEmpty: true, },
	city: { notEmpty: true, },
	country: { notEmpty: true, },
};

export const CompanyEditInitial: CompanyEdit = {
	id: "",
	name: "",
	nip: "",
	address: "",
	postalCode: "",
	city: "",
	country: "",
};

export type CompanyUserMore={
	uoId: string;
	id: string;
	name: string;
	email: string;
	lastLogged: string;
	status: UOStatus;
};

export type VindicationEvent={
	vId?: string;
	vFullId?: string;
	vEcId?: string;
	vExtId?: string;
	vDebtor?: string;
	id: string;
	type: string;
	date: string;
	message: string;
	fileId?: string;
	fileName?: string;
	fileDate?: string;
};

export type VindicationPaymentAllocation={
	doc: string;
	capital: string;
	interest: string;
	costs: string;
};

export type VindicationPayment={
	vId?: string;
	vFullId?: string;
	vEcId?: string;
	vExtId?: string;
	vDebtor?: string;
	date: string;
	amount: string;
	message: string;
	capital: string;
	interest: string;
	costs: string;
	waiting: boolean;
	allocations: Array<VindicationPaymentAllocation>;
};

export type VindicationViewFile={
	vId?: string;
	vFullId?: string;
	vEcId?: string;
	vExtId?: string;
	vDebtor?: string;
	dbId: string;
	type: string;
	name: string;
	date: string;
	description: string;
};

export type VindicationViewReceivable={
	initialCapital: string;
	initialInterests: string;
	initialCosts: string;
	actualCapital: string;
	actualInterests: string;
	actualCosts: string;
	recoveredCapital: string;
	recoveredInterests: string;
	recoveredCosts: string;
	vId?: string;
	type: LangString;
	number: string;
	sellDate: string;
	paymentDate: string;
	initial: string;
	actual: string;
	recovered: string;
};

export type VindicationViewCost={
	date: string;
	stage: string;
	type: string;
	amount: string;
	description: string;
	affectsBalance: boolean;
	invoiced: boolean;
	invoice: string;
	currency: string;
};

export type VindicationDetails={
	initialCapital: string;
	initialInterests: string;
	initialCosts: string;
	actualCapital: string;
	actualInterests: string;
	actualCosts: string;
	recoveredCapital: string;
	recoveredInterests: string;
	recoveredCosts: string;
	id: string;
	fullId: string;
	added: string;
	ecId: string;
	tax: string;
	debtor: string;
	extId: string;
	currency: string;
	changed: string;
	stageChanged: string;
	status: OrderStatus;
	stage: string;
	ecStatus: string|null;
	amicable: boolean;
	judicial: boolean;
	domestic: boolean;
	chargeInterests: boolean;
	chargeCosts: boolean;
	invoiceMonitoring: boolean;
	invoiceMonitoringAutomatic: boolean;
	creditor?: string;
	/** Ext */
	client?: string;
	/** Ext */
	clientEcId?: string|null;
	/** Ext */
	clientFistName?: string;
	/** Ext */
	clientLastName?: string;
	/** Ext */
	clientTax?: string;
	/** Ext */
	rejectionReason?: LangString;
	/** Ext */
	acceptedBy?: string;
	/** Ext */
	worker?: string;
	/** Ext */
	changed2?: string;
	grace: string;
	address: string;
	postalCode: string;
	city: string;
	country: string;
	insurer: string;
	events: Array<VindicationEvent>;
	payments: Array<VindicationPayment>;
	files: Array<VindicationViewFile>;
	receivables: Array<VindicationViewReceivable>;
	receivablesFile: VindicationViewFile;
	vindications: Array<VindicationTableInfo>;
	otherVindications: Array<DebtExchangeInfo>;
	costs: Array<VindicationViewCost>;
	courtRefNumber: string;
	bailiffRefNumber: string;
	executiveApplicationDate: string;
	creditorName: string;
	creditorAddress: string;
	creditorCountry: string;
	/** Ext */
	alreadyVindicated?: boolean;
	/** Ext */
	otherContacts?: string;
	/** Ext */
	phone?: string;
	/** Ext */
	email?: string;
	/** Ext */
	pricesFile?: string;
	/** Ext */
	pricesFileName?: string;
	initial: string;
	actual: string;
	recovered: string;
};

export type MonitoredClient={
	id: string;
	name: string;
	tax: string;
	balance: { [string]: string };
	scenario: string;
	scenarioLabel: LangString;
	stage: MonitoringStage;
	finalStage: MonitoringStage;
	overdue: number|null;
	phone: string;
	email: string;
	paused: boolean;
	validPhone: boolean;
	validEmail: boolean;
	vindication: boolean;
	monitoredInvoices: number;
	grace: boolean;
	excluded: boolean;
};

export type InvoiceSynchronizationStatus = 'Success'|'Failure';
export type MonitoredHistoryImport={
	id: string;
	time: string;
	type: InvoiceSynchronizationType;
	status: InvoiceSynchronizationStatus;
	error: string;
};

export type MonitoredNotification={
	id: string;
	sendDate: string;
	clientId: string;
	client: string;
	clientTax: string;
	email: string;
	phone: string;
	status: string;
	stage: MonitoringStage;
	emailQueued: boolean;
	smsQueued: boolean;
};

export type InvoiceMonitoringEventType = 'Payment'|'Settlement'|'Usage'|'AdminExtra'|'AdminSubscription';
export type IMSubscriptionHistory={
	id: string;
	time: string;
	number: string;
	invoiceFilename: string;
	amount: string;
	type: InvoiceMonitoringEventType;
	paymentStatus: PaymentStatus;
	paymentId: string;
};

export type VindicationDocument={
	type: string;
	number: string;
	amount: string;
	sell: string;
	payment: string;
	invoiceId: string;
};

export type VindicationContact={
	emailSend: boolean;
	email: string;
	phoneSend: boolean;
	phone: string;
};

export type VindicationCheckedContact={
	checked: boolean;
	value: string;
};

export type VindicationRegisterData={
	vindicationId: string;
	clientId: string;
	tax: string;
	name: string;
	postalCode: string;
	city: string;
	address: string;
	country: string;
	documents: Array<VindicationDocument>;
	documentsDbId: string;
	insurer: string|null;
	domestic: boolean;
	currency: string;
	amicable: boolean;
	judicial: boolean;
	interest: boolean;
	costs: boolean;
	extId: string;
	interestRate: string|null;
	files: Array<VindicationFile>;
	otherContacts: string;
	email: string;
	phone: string;
	contacts: Array<VindicationContact>;
	emails: Array<VindicationCheckedContact>;
	phones: Array<VindicationCheckedContact>;
	creditorName: string;
	creditorAddress: string;
	creditorCountry: string;
};

export const VindicationRegisterDataValidation: ValidationRules = {
	vindicationId: { },
	clientId: { },
	tax: { },
	name: { notEmpty: true, notNull: true, },
	postalCode: { notEmpty: true, notNull: true, },
	city: { notEmpty: true, notNull: true, },
	address: { notEmpty: true, notNull: true, },
	country: { notEmpty: true, notNull: true, },
	documents: { notEmpty: true, },
	documentsDbId: { },
	insurer: { },
	domestic: { },
	currency: { notNull: true, },
	amicable: { },
	judicial: { },
	interest: { },
	costs: { },
	extId: { },
	interestRate: { },
	files: { },
	otherContacts: { },
	email: { },
	phone: { },
	contacts: { },
	emails: { },
	phones: { },
	creditorName: { },
	creditorAddress: { },
	creditorCountry: { },
};

export const VindicationRegisterDataInitial: VindicationRegisterData = {
	vindicationId: "",
	clientId: "",
	tax: "",
	name: "",
	postalCode: "",
	city: "",
	address: "",
	country: "",
	documents: [],
	documentsDbId: "",
	insurer: null,
	domestic: false,
	currency: "",
	amicable: false,
	judicial: false,
	interest: false,
	costs: false,
	extId: "",
	interestRate: null,
	files: [],
	otherContacts: "",
	email: "",
	phone: "",
	contacts: [],
	emails: [],
	phones: [],
	creditorName: "",
	creditorAddress: "",
	creditorCountry: "",
};

export type OrderRegistrationData={
	name: string;
	nip: string;
	desc: string;
};

export const OrderRegistrationDataValidation: ValidationRules = {
	name: { notEmpty: true, },
	nip: { },
	desc: { },
};

export const OrderRegistrationDataInitial: OrderRegistrationData = {
	name: "",
	nip: "",
	desc: "",
};

export type NotificationMode = 'Summary'|'Notification'|'Email';
export type UserNotification={
	type: NotificationType;
	orderId?: string;
	mode: Array<NotificationMode>;
};

export type UserSettings={
	name: string;
	email: string;
	phone: string;
	language: Language;
	settings: UserOrganizationConfig;
	userSettings: UserConfig;
	notifications: Array<UserNotification>;
};

export const UserSettingsValidation: ValidationRules = {
	name: { notEmpty: true, },
	email: { notEmpty: true, },
	phone: { notEmpty: true, },
	language: { },
	settings: { },
	userSettings: { },
	notifications: { },
};

export const UserSettingsInitial: UserSettings = {
	name: "",
	email: "",
	phone: "",
	language: '',
	settings: "",
	userSettings: "",
	notifications: [],
};

export type ContactMessage={
	topic: string;
	message: string;
};

export const ContactMessageValidation: ValidationRules = {
	topic: { notEmpty: true, },
	message: { notEmpty: true, },
};

export const ContactMessageInitial: ContactMessage = {
	topic: "",
	message: "",
};

export type InvoiceMonitoringImportInvoice={
	number: string;
	amount: string;
	paid: string;
	sell: string;
	payment: string;
	currency: string;
};

export type InvoiceMonitoringImportClient={
	name: string;
	tax: string;
	address: string;
	phone: string;
	postalCode: string;
	city: string;
	email: string;
	country: string;
	invoices: Array<InvoiceMonitoringImportInvoice>;
};

export interface UserAPI {
	acceptGraceVindication(vId: string): Promise<void>;
	acceptTerms(terms: Array<string>): Promise<Array<AgreementInfo>>;
	addAgreement(name: string): Promise<boolean>;
	addCompany(tax: string, company: RegisterCompanyInfo): Promise<boolean|ErrorResult>;
	addCompanyByNIP(tax: string): Promise<RegisterCompanyInfo>;
	addMonitored(nips: Array<NIP>): Promise<number|ErrorResult>;
	addUserToCompany(ue: UserEdit): Promise<string|ErrorResult>;
	addVindicationFiles(vId: string, files: Array<VindicationFile>): Promise<void|ErrorResult>;
	addVindicationPayment(vId: string, reg: VindicationPaymentRegistration): Promise<void>;
	applyMonitoringSettings(mode: MonitoringScenarioChangeMode): Promise<boolean>;
	buyDebt(id: string, price: string, message: string): Promise<boolean>;
	buyMonitoringPacket(type: InvoiceMonitoringPacketType, mode: string): Promise<string>;
	cancelRemoteHelpToken(token: string): Promise<void>;
	changePassword(pf: UserPassword): Promise<void|ErrorResult>;
	checkDebtor(tax: string): Promise<DebtorQueryInfo>;
	checkDocuments(docs: Array<ReceivableInfo>): Promise<Array<string>>;
	createCompanyKey(id: string, role: ApiKeyRole): Promise<string>;
	createInvoiceMonitoringDataIntegratorKey(): Promise<string>;
	deleteMonitored(ids: Array<string>): Promise<void>;
	disableCompanyKey(id: string, role: ApiKeyRole): Promise<boolean>;
	disableInvoice(id: string, disabled: boolean): Promise<boolean>;
	disableInvoiceMonitoringIntegration(): Promise<boolean>;
	excludeMonitoringScenario(clientId: string, exclude: boolean): Promise<boolean>;
	exportVindications(query: TableQuery): Promise<FileInfo>;
	generateReport(id: string): Promise<FileInfo>;
	getCompanies(): Promise<Array<CompanyInfo>>;
	getCompanyKeys(id: string): Promise<Array<CompanyApiKey>>;
	getCompanyOrders(id: string, query: TableQuery): Promise<TableResult<OrderInfo>>;
	getCompanyVindications(id: string, query: TableQuery): Promise<TableResult<VindicationTableInfo>>;
	getDebt(id: string): Promise<DebtExchangeDetails>;
	getDebts(query: TableQuery): Promise<TableResult<DebtExchangeInfo>>;
	getDebtsSummary(): Promise<DebtsSummary>;
	getDifficultDebts(): Promise<Array<DifficultDebtInfo>>;
	getEcStages(): Promise<Array<LangOption>>;
	getEventTypes(): Promise<Array<LangOption>>;
	getImsigData(number: string): Promise<IMSIGDataInfo>;
	getInvoiceMonitoringIntegrationSettings(): Promise<InvoiceMonitoringIntegrationSettings>;
	getInvoiceMonitoringSettings(): Promise<MonitoringSettings>;
	getInvoiceMonitoringSubscription(): Promise<InvoiceMonitoringSubscription>;
	getMonitored(): Promise<Array<MonitoredEntry>>;
	getMonitoredClient(id: string): Promise<MonitoredClientDetails>;
	getMonitoredLeft(): Promise<number|null>;
	getMonitoredVindicationInvoices(id: string): Promise<Array<MonitoredInvoice>>;
	getMonitoringDesktopInformation(): Promise<MonitoringDesktopInformation>;
	getMonitoringScenario(id: string): Promise<MonitoringScenarioDetails>;
	getMonitoringScenarios(): Promise<Array<LangOption>>;
	getMonitoringScenarioSteps(clientId: string): Promise<Array<MonitoringScenarioStepSetup>>;
	getNewNotifications(before: string): Promise<Array<UserWebNotification>>;
	getNotifications(): Promise<Array<UserWebNotification>>;
	getPaymentStatus(): Promise<PaymentStatus>;
	getRemoteHelpToken(): Promise<string>;
	getRightsDicts(): Promise<VindicationDictionaries>;
	getUser(id: string): Promise<UserEdit>;
	getUserAgreements(): Promise<Array<UserAgreementInfo>>;
	getUserCompany(id: string): Promise<UserCompany>;
	getUserEditCompany(id: string): Promise<CompanyEdit>;
	getUsers(): Promise<Array<CompanyUserMore>>;
	getUsersRights(): Promise<Array<LangOption>>;
	getVindication(id: string): Promise<VindicationDetails>;
	getVindicationDictionaries(): Promise<VindicationDictionaries>;
	getVindicationsForDebtor(nip: string): Promise<Array<VindicationTableInfo>>;
	hasAnyEvents(): Promise<boolean>;
	hasAnyOrders(typeId: string): Promise<boolean>;
	hasAnyVindications(): Promise<boolean>;
	invoiceMonitoringDeleteInvoices(captcha: string): Promise<boolean|null>;
	invoiceMonitoringPurge(captcha: string): Promise<boolean|null>;
	logout(): Promise<void>;
	markAsPayedInvoice(id: string, date: string): Promise<boolean>;
	pauseMonitoringScenario(clientId: string, paused: boolean): Promise<boolean>;
	queryEvents(query: TableQuery): Promise<TableResult<VindicationEvent>>;
	queryMonitoredClient(query: TableQuery): Promise<TableResult<MonitoredClient>>;
	queryMonitoredHistoryImport(query: TableQuery): Promise<TableResult<MonitoredHistoryImport>>;
	queryMonitoredInvoices(query: TableQuery): Promise<TableResult<MonitoredInvoice>>;
	queryMonitoredNotification(query: TableQuery): Promise<TableResult<MonitoredNotification>>;
	queryOrders(query: TableQuery): Promise<TableResult<OrderInfo>>;
	queryPayments(query: TableQuery): Promise<TableResult<VindicationPayment>>;
	querySubscriptionHistory(query: TableQuery): Promise<TableResult<IMSubscriptionHistory>>;
	queryVindications(query: TableQuery): Promise<TableResult<VindicationTableInfo>>;
	registerMultipleVindications(vindications: Array<VindicationRegisterData>): Promise<void|ErrorResult>;
	registerOrder(typeId: string, data: OrderRegistrationData): Promise<void|ErrorResult>;
	registerVindication(vindication: VindicationRegisterData): Promise<Array<string>|ErrorResult>;
	rejectGraceVindication(vId: string): Promise<void>;
	saveUser(ui: UserSettings): Promise<boolean|ErrorResult>;
	selectCompany(uoId: string): Promise<{}>;
	sendContactMessage(message: ContactMessage): Promise<boolean>;
	setInvoiceMonitoringIntegration(type: InvoiceSynchronizationType, key: string, key2: string, key3: string): Promise<boolean>;
	setInvoiceMonitoringSettings(s: MonitoringSettings): Promise<boolean>;
	synchronizeInvoicesNow(): Promise<void>;
	updateCompany(company: CompanyEdit): Promise<boolean>;
	updateInvoiceMonitoringScenario(scenarioId: string, steps: Array<MonitoringScenarioStepSetup>): Promise<boolean>;
	updateMonitoringScenario(clientId: string, scenarioId: string, steps: Array<MonitoringScenarioStepSetup>): Promise<boolean>;
	updateUser(ue: UserEdit): Promise<void>;
	updateVindicationContacts(vId: string, data: VindicationRegisterData): Promise<void|ErrorResult>;
	uploadInvoices(invoices: Array<InvoiceMonitoringImportClient>): Promise<string>;
	withdrawAgreement(name: string): Promise<boolean>;
}

export type UserAPIQuery = {
	acceptGraceVindication: void,
	acceptTerms: Array<AgreementInfo>,
	addAgreement: boolean,
	addCompany: boolean|ErrorResult,
	addCompanyByNIP: RegisterCompanyInfo,
	addMonitored: number|ErrorResult,
	addUserToCompany: string|ErrorResult,
	addVindicationFiles: void|ErrorResult,
	addVindicationPayment: void,
	applyMonitoringSettings: boolean,
	buyDebt: boolean,
	buyMonitoringPacket: string,
	cancelRemoteHelpToken: void,
	changePassword: void|ErrorResult,
	checkDebtor: DebtorQueryInfo,
	checkDocuments: Array<string>,
	createCompanyKey: string,
	createInvoiceMonitoringDataIntegratorKey: string,
	deleteMonitored: void,
	disableCompanyKey: boolean,
	disableInvoice: boolean,
	disableInvoiceMonitoringIntegration: boolean,
	excludeMonitoringScenario: boolean,
	exportVindications: FileInfo,
	generateReport: FileInfo,
	getCompanies: Array<CompanyInfo>,
	getCompanyKeys: Array<CompanyApiKey>,
	getCompanyOrders: TableResult<OrderInfo>,
	getCompanyVindications: TableResult<VindicationTableInfo>,
	getDebt: DebtExchangeDetails,
	getDebts: TableResult<DebtExchangeInfo>,
	getDebtsSummary: DebtsSummary,
	getDifficultDebts: Array<DifficultDebtInfo>,
	getEcStages: Array<LangOption>,
	getEventTypes: Array<LangOption>,
	getImsigData: IMSIGDataInfo,
	getInvoiceMonitoringIntegrationSettings: InvoiceMonitoringIntegrationSettings,
	getInvoiceMonitoringSettings: MonitoringSettings,
	getInvoiceMonitoringSubscription: InvoiceMonitoringSubscription,
	getMonitored: Array<MonitoredEntry>,
	getMonitoredClient: MonitoredClientDetails,
	getMonitoredLeft: number|null,
	getMonitoredVindicationInvoices: Array<MonitoredInvoice>,
	getMonitoringDesktopInformation: MonitoringDesktopInformation,
	getMonitoringScenario: MonitoringScenarioDetails,
	getMonitoringScenarios: Array<LangOption>,
	getMonitoringScenarioSteps: Array<MonitoringScenarioStepSetup>,
	getNewNotifications: Array<UserWebNotification>,
	getNotifications: Array<UserWebNotification>,
	getPaymentStatus: PaymentStatus,
	getRemoteHelpToken: string,
	getRightsDicts: VindicationDictionaries,
	getUser: UserEdit,
	getUserAgreements: Array<UserAgreementInfo>,
	getUserCompany: UserCompany,
	getUserEditCompany: CompanyEdit,
	getUsers: Array<CompanyUserMore>,
	getUsersRights: Array<LangOption>,
	getVindication: VindicationDetails,
	getVindicationDictionaries: VindicationDictionaries,
	getVindicationsForDebtor: Array<VindicationTableInfo>,
	hasAnyEvents: boolean,
	hasAnyOrders: boolean,
	hasAnyVindications: boolean,
	invoiceMonitoringDeleteInvoices: boolean|null,
	invoiceMonitoringPurge: boolean|null,
	logout: void,
	markAsPayedInvoice: boolean,
	pauseMonitoringScenario: boolean,
	queryEvents: TableResult<VindicationEvent>,
	queryMonitoredClient: TableResult<MonitoredClient>,
	queryMonitoredHistoryImport: TableResult<MonitoredHistoryImport>,
	queryMonitoredInvoices: TableResult<MonitoredInvoice>,
	queryMonitoredNotification: TableResult<MonitoredNotification>,
	queryOrders: TableResult<OrderInfo>,
	queryPayments: TableResult<VindicationPayment>,
	querySubscriptionHistory: TableResult<IMSubscriptionHistory>,
	queryVindications: TableResult<VindicationTableInfo>,
	registerMultipleVindications: void|ErrorResult,
	registerOrder: void|ErrorResult,
	registerVindication: Array<string>|ErrorResult,
	rejectGraceVindication: void,
	saveUser: boolean|ErrorResult,
	selectCompany: {},
	sendContactMessage: boolean,
	setInvoiceMonitoringIntegration: boolean,
	setInvoiceMonitoringSettings: boolean,
	synchronizeInvoicesNow: void,
	updateCompany: boolean,
	updateInvoiceMonitoringScenario: boolean,
	updateMonitoringScenario: boolean,
	updateUser: void,
	updateVindicationContacts: void|ErrorResult,
	uploadInvoices: string,
	withdrawAgreement: boolean,
};

export type ActivateAccountData={
	code: string;
	password: string;
	agreements: Array<string>;
};

export type ActivateAccountInfo={
	email: string;
};

export type BannerInfo={
	url: LangString;
	name: LangString;
	img: LangString;
	blank: boolean;
};

export type Dictionaries={
	documentTypes: Array<LangOption>;
	insurers: Array<LangOption>;
	currencies: Array<LangOption>;
	globalCountries: Array<string>;
	stages: Array<LangOption>;
};

export type UserCompanyInfo={
	id: string;
	orgId: string;
	name: string;
	available: boolean;
	demo: boolean|null;
};

export type ApplicationSettings={
	userId: string;
	name: string;
	firstname: string;
	lastname: string;
	email: string;
	phone: string;
	agreements: Array<AgreementInfo>;
	rights: Array<UserRight>;
	viewMonitoringMenu: boolean;
	viewKnowledgeMenu: boolean;
	language: Language;
	orgId: string;
	org: string;
	orgInsurer?: boolean|null;
	insurers?: Array<LangOption>;
	country: string;
	settings: UserOrganizationConfig;
	userSettings: UserConfig;
	technicalMessage: LangString;
	systemUserId: string;
	organizations: Array<UserCompanyInfo>;
	services: Array<AccessRight>;
	demo: boolean|null;
	notifications: Array<UserNotification>;
};

export type StaticPage={
	title: string;
	content: string;
};

export type RegisterForm={
	email: string;
	password: string;
	name: string;
	phone: string;
	tax: string;
	promo: string;
	agreements: Array<string>;
};

export const RegisterFormValidation: ValidationRules = {
	email: { email: true, notEmpty: true, },
	password: { notEmpty: true, },
	name: { notEmpty: true, },
	phone: { },
	tax: { notEmpty: true, pattern: "^[A-Z]{2}.+$", patternMessage: "validation.required", },
	promo: { },
	agreements: { },
};

export const RegisterFormInitial: RegisterForm = {
	email: "",
	password: "",
	name: "",
	phone: "",
	tax: "",
	promo: "",
	agreements: [],
};

export interface PublicAPI {
	activateAccount(data: ActivateAccountData): Promise<void|ErrorResult>;
	changePassword(code: string, newPassword: string): Promise<void|ErrorResult>;
	check(): Promise<boolean>;
	confirmAccount(code: string): Promise<ErrorResult>;
	getActivateAccountInfo(code: string): Promise<ActivateAccountInfo>;
	getAgreements(): Promise<Array<AgreementInfo>>;
	getBanner(): Promise<BannerInfo>;
	getCompanyInfo(country: string, tax: string): Promise<RegisterCompanyInfo>;
	getCountry(): Promise<string>;
	getDictionaries(): Promise<Dictionaries>;
	getDocumentTypes(): Promise<Array<LangOption>>;
	getInsurers(): Promise<Array<LangOption>>;
	getRegisteredReport(): Promise<FileInfo>;
	getSettings(): Promise<ApplicationSettings>;
	getStaticPage(page: string): Promise<StaticPage>;
	isValidCode(code: string): Promise<boolean>;
	login(username: string, password: string): Promise<boolean|ErrorResult>;
	register(form: RegisterForm): Promise<RegisterCompanyInfo|ErrorResult>;
	register2(companyInfo: RegisterCompanyInfo): Promise<boolean|ErrorResult>;
	registerWithVindication(vindication: VindicationRegisterData, user: RegisterForm, docsFile: string): Promise<Array<string>>;
	resetPassword(email: string): Promise<string|ErrorResult>;
	updateRegisterVindicationContacts(regId: string, data: VindicationRegisterData): Promise<void>;
}

export type PublicAPIQuery = {
	activateAccount: void|ErrorResult,
	changePassword: void|ErrorResult,
	check: boolean,
	confirmAccount: ErrorResult,
	getActivateAccountInfo: ActivateAccountInfo,
	getAgreements: Array<AgreementInfo>,
	getBanner: BannerInfo,
	getCompanyInfo: RegisterCompanyInfo,
	getCountry: string,
	getDictionaries: Dictionaries,
	getDocumentTypes: Array<LangOption>,
	getInsurers: Array<LangOption>,
	getRegisteredReport: FileInfo,
	getSettings: ApplicationSettings,
	getStaticPage: StaticPage,
	isValidCode: boolean,
	login: boolean|ErrorResult,
	register: RegisterCompanyInfo|ErrorResult,
	register2: boolean|ErrorResult,
	registerWithVindication: Array<string>,
	resetPassword: string|ErrorResult,
	updateRegisterVindicationContacts: void,
};

