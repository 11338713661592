//@flow
import React, {Ref, useEffect, useMemo, useState} from 'react';
import msgs, {currentLang, formatString, getLangOptions, getLangValue, LangContext, LangLink, useMsgs} from "./Language";
import {FieldArray, FormikBag} from "formik";
import {BForm, Form} from "./Form";
import type {
    AccessRight, Dictionaries,
    ImportResult,
    LangOption, MonitoredClientDetails, MonitoredInvoice, Option, VindicationCheckedContact, VindicationContact,
    VindicationDocument,
    VindicationFile,
    VindicationRegisterData,
    VindicationTableInfo
} from "../api";
import {ReceivableInfo, RegisterCompanyInfo, VindicationRegisterDataInitial} from "../api";
import {
    Alert,
    Button,
    Col,
    FormControl,
    InputGroup,
    OverlayTrigger,
    ProgressBar,
    Row,
    Tab,
    Table,
    Tabs,
    Tooltip
} from "react-bootstrap";
import {controlWaitGlass, DeleteButton, Hint, Icon, IconAlert} from "./Components";
import type {UploadInfo} from "./Upload";
import {DefaultDropzone, deleteUpload, getDisplayLink, getDownloadLink, mimeTypeToIcon, SingleFileInput, upload} from "./Upload";
import {DateInput, formatDate, formatDateTime, parseDate} from "./DateTime";
import BigNumber from "bignumber.js";
import {NetworkDataProcessor} from "./Network";
import {addMoney, formatMoney, parseMoney} from "./Utils";
import {emitEvent} from "./Events";
import Validate from "./Validation";
import type {ErrorResult} from "./Validation";
import cn from 'classnames';
import {ClientInvoicesList} from "./InvoiceMonitoringComponents";
import {DataTableRowSelection} from "./DataTable";

type AddVindicationState =
    /** Ekran z polem podania numeru NIP - 310-1  */
    "tax"|
    /** Ekran z opcją dodania do istniejącego długu 310-3 */
    "addToExisting"|
    /** Ekran dodania informacji o firmie, gdy nie podano NIP */
    "company"|
    /** Ekran wprowadzania ręcznie dokumentów - 310-4 */
    "documents"|
    /** Ekran importu danych (dokumentów) z pliku */
    "documentsFromFile"|
    /** Dokumenty do wyboru, dla monitorowanego kontrahenta */
    "documentsMonitored" |
    /** Parametry zlecenia 310-5 */
    "params"|
    /** Dodatkowy ekran dla trybu windykacja z rejestracją */
    "register"|
    /** Dane kontaktowe do dłużnika 310-6 */
    "contact"|
    /** Ekran z informacją o zarejestrowaniu */
    "done"|
    /** Ekran dodawania wielu długów - po poprawnym imporcie pliku */
    "multiple" |
    /** Ekran z informacją o dodaniu wielu długów */
    "multiple_done";

export type ForMonitoredClient = {
    /** Kontrahent, dla którego dodajemy windykację manualnie */
    client: MonitoredClientDetails;
    /** Faktury, które możliwe są do wybrania */
    invoices: Array<MonitoredInvoice>;
}

export interface AddVindicationAPI {
    /** Tytuł tego etapu */
    +title: string;
    /** Aktualny etap dodawania długu */
    +stage: AddVindicationState;
    /** Kliknięcie wstecz */
    handleBack: (e: SyntheticEvent) => void;
    /** Kliknięcie do przodu */
    handleNext: (e: SyntheticEvent) => void;
    handleClose?: (e: SyntheticEvent) => void;
    backLabel?: string;
    /** Opcjonalna treść dla przycisku następny */
    nextLabel?: string;
    /** Komunikat do wyświetlenia dla przycisku dalej */
    nextMessage?: React$Node;
    /** Treść tego etapu */
    render: () => React$Node;
}

type ReceivableColumns = {
    DocumentType: string;
    Number: string;
    Amount: string;
    SellDate: string;
    PaymentDate: string;
}

type MultipleDebtsColumns = ReceivableColumns & {
    // /** Czy jest błąd dla tego dokumentu (duplikat) */
    // NumberError?: boolean;
    NIP: string;
    DebtorName: string;
    PostalCode: string;
    City: string;
    Address: string;
    Country: string;
    DebtorId: string;
    // Dane windykacji
    Domestic: boolean;
    Currency: boolean;
    ChargeInterests: boolean;
    InterestRate: string;
    ChargeCosts: boolean;
    Email: string;
    EmailNotification: boolean|null;
    Phone: string;
    PhoneNotification: boolean|null;
    Insured: boolean;

    OriginalCreditorName: string|null;
    OriginalCreditorAddress: string|null;
    OriginalCreditorCountry: string|null;
}

type FileInfo = {
    meta: VindicationFile;
    upload: UploadInfo;
}

/** Interfejs do komunikacji z serwerem */
export interface VindicationServerAPI {
    /** Pobranie kraju użytkownika */
    getCountry(): Promise<string>;
    /** Słowniki aplikacji */
    getDictionaries(): Promise<Dictionaries>;
    /** Ubezpieczyciele dostępni dla tej organizacji */
    getInsurers(): Promise<LangOption>;
    /** Pobranie informacji o firmie z BIR */
    getCompanyInfo(country: string, tax: string): Promise<RegisterCompanyInfo>;
    /** Inne windykacje dla danego dłużnika */
    getVindicationsForDebtor(nip: string): Promise<Array<VindicationTableInfo>>;
    /** Dodanie windykacji */
    registerVindication(vindication: VindicationRegisterData): Promise<Array<string>|ErrorResult>;
    /** Pobranie pliku dla zalogowanego użytkownika */
    generateReport(id: string): Promise<FileInfo>;
    /** Pobranie pliku raportu, dla niezalogowanego użytkownika */
    getRegisteredReport(): Promise<FileInfo>;
    /** Aktualizacja danych kontaktowych dłużnika dla zalogowanego użytkownika */
    updateVindicationContacts(vId: string, data: VindicationRegisterData): Promise<void|ErrorResult>;
    /** Aktualizacja danych kontaktowych dla niezalogowanego użytkownika */
    updateRegisterVindicationContacts(regId: string, data: VindicationRegisterData): Promise<void>;
    /** Rejestracja wielu windykacji z pliku */
    registerMultipleVindications(vindications: Array<VindicationRegisterData>): Promise<void|ErrorResult>;
    /** Sprawdzenie, czy nie ma kolizji numerów dokumentów */
    checkDocuments(docs: Array<ReceivableInfo>): Promise<Array<string>>;
}

type Props = {
    /** Czy wersja dla zalogowanego użytkownika */
    logged: boolean;
    /** Informacje o usłudze windykacji */
    service: AccessRight;
    /** Informacje o globalnej usłudze windykacji */
    global: AccessRight;
    children: (form: AddVindicationAPI) => React$Node;
    /** Funkcja wywoływana w przypadku rezygnacji przez użytkownika z dodawania */
    onCancel: () => void;
    onSuccess: () => void;
    /** Funkcja do generowania formularza rejestracji użytkownika */
    registerRender?: (state: AddState) => AddVindicationAPI;
    /** Funkcja generująca link do podglądu */
    viewLink?: (id) => string;
    /** Interfejs do komunikacji z serwerem */
    serverApi: VindicationServerAPI;
    /** Nazwa zdarzenia, jakie ma być wysłane po zarejestrowaniu windykacji */
    eventName?: string;
    /** Maksymalny rozmiar pliku, który można wgrać */
    maxSize?: number;
    /** Czy wyświetlać informacje o wysokości odsetek */
    displayInterestRate?: boolean;
    /** Informacje o kontrahencie, dla którego ma być utworzona windykacja */
    client?: ForMonitoredClient;
}

export type AddState = {
    refreshId: number;
    /** Id zarejestrowanej sprawy */
    regId: string|null;
    /** Pełny numer sprawy */
    regFullId: string|null;
    /** Czas rejestracji */
    regDate: Date|null;
    /** Typy dokumentu */
    documentTypes: Array<LangOption>;
    /** Domyślny typ dokumentu */
    defaultDocumentType: string;
    /** Ubezpieczyciele */
    insurers: Array<LangOption>;
    /** Dostępne waluty */
    currencies: Array<LangOption>;
    /** Kraj użytkownika na podstawie numeru IP */
    country: string|null;
    /** Kraje dla cennika globalnego */
    globalCountries: Array<string>;
    /** Czy dane o firmie były automatycznie wypełnione */
    autoCompany: boolean;
    /** Czy sprawdzać stan dokumentów */
    validateDocuments: boolean;
    /** Aktualny etap dodawania długu */
    stage: AddVindicationState;
    /** Dane dla importu jednego długu */
    value: VindicationRegisterData;

    /** Zakładka, która jest aktywna na pierwszym roku */
    newDebtTab: string;

    /* Czy wyświetlać błędy dla importu wielu długów */
    debtsTouched: boolean;
    /** Czy krajowe, czy zagraniczne w pliku? (dla importu wielu) */
    debtsDomestic: boolean;
    /** Zakres zlecenia dla importu wielu z pliku */
    debtsAmicable: boolean;
    /** Zakres zlecenia dla importu wielu z pliku */
    debtsJudicial: boolean;
    /** Ubezpieczyciel dla importu wielu z pliku */
    debtsInsurer: string;
    /** Dane dla importu wielu długów */
    debts: Array<VindicationRegisterData>;

    /** Dodatkowe informacje o przesyłanych plikach do windykacji */
    files: Array<FileInfo>;
    /** Inne długi dla tego dłużnika */
    others: Array<VindicationTableInfo>|null;
    /** Błędy importu wielu z pliku */
    importErrors: Array<{ row: number, errors: string[] }>|null;
    /** Nazwa pliku dla którego jest import */
    importFileName: string|null;

    /* Dla dodanie długu z rejestracją */
    /** Czy tryb długów z pliku */
    docsFromFile: boolean;
    /** Dodany plik */
    docsFile: FileInfo|null;
}

const emptyDocument = (def) => {
    return {
        type: def || "",
        number: "",
        amount: "",
        payment: "",
        sell: ""
    }
};

const emptyContact = () : () => VindicationContact => ({
    email: "",
    emailSend: false,
    phone: "",
    phoneSend: false
});

const emptyCheckedContact = () : () => VindicationCheckedContact => ({
    checked: true,
    value: "",
})

const StageNIPValidator={
    tax: { notEmpty: true }
}

const StageCompanyValidator = {
    name: { notEmpty: true },
    postalCode: { notEmpty: true },
    city: {notEmpty: true },
    address: { notEmpty: true, },
    country: { notEmpty: true, }
}

function isValidPaymentDate(type: string, sell: string, payment: string): boolean {
    if(!type || !sell || !payment) return true; // zwracamy true, bo błąd jest w innych miejscach i nie można sprawdzić daty
    if(type==="2") return true; // dla nakazów nie sprawdzamy daty
    return sell<=payment;   // data sprzedaży musi być przed datą płatności
}

const DocumentType = ({ value, onChange, types, error, ...props }) => {
    const msgs=useMsgs();
    const options=useMemo(() => {
        if(currentLang.code==="en") return types.filter((t: LangOption) => t.label.pl!==t.label.en);
        else return types;
    }, [ msgs.gui.language, types ]);

    return <BForm.Control
        as="select" value={value} isInvalid={error}
        onChange={(e) => onChange(e.target.value)}
        {...props}
    >
        {getLangOptions(options)}
    </BForm.Control>;
}

let _idGen=0;
function createTooltip(msg: string) {
    const id="tt_"+(++_idGen);
    return <Tooltip id={id}>{msg}</Tooltip>;
}

const DocumentNumber = ({ value, onChange, error, errorMessage,  ...props }) => {
    const msgs=useMsgs();
    const [ tooltip, setTooltip ] = useState(() => error?createTooltip(errorMessage?msgs.gui[errorMessage]:msgs.gui.hintNoDocumentNr):null);
    useEffect(() => {
        if(error && !tooltip) setTooltip(createTooltip(errorMessage?msgs.gui[errorMessage]:msgs.gui.hintNoDocumentNr));
    }, [ error ])

    const res=<BForm.Control
            type="text" value={value}
            isInvalid={error}
            onChange={(e) => onChange(e.target.value)}
            {...props}
        />;
    if(!tooltip) return res;
    return <OverlayTrigger
        placement="top"
        overlay={tooltip}
        show={!error?false:undefined}
    >{res}</OverlayTrigger>
}

const AmountInput = ({ value, onChange, error, ...props }) => {
    return <BForm.Control
            type="number"
            value={value}
            isInvalid={error}
            onChange={(e) => onChange(e.target.value)}
            {...props}
        />;
}

const DatePicker = ({ value, onChange, error, otherValue, ...props }: {}) => {
    return <DateInput
            value={value}
            onChange={onChange}
            isInvalid={error}
            openToDate={!value && otherValue && parseDate(otherValue)}
            {...props}
        />
}

/**
 * Komponent do dodawania nowej windykacji. Komponent należy umieścić wewnątrz obszaru, jak Formikowy
 * form i inne.
 */
export class AddVindication extends React.Component<Props, AddState> {
    static background: null|() => void=null;

    formik: Ref<FormikBag>;
    static idGen: number = 0;
    service: AccessRight;
    global: AccessRight;
    docOther: LangOption;
    docInvoice: LangOption;
    clientInvoices: Map<String, MonitoredInvoice>;

    constructor(props: Props) {
        super(props);
        this.formik=React.createRef();
        if(this.props.logged) {
            this.service=this.props.service;
            this.global=this.props.global;
        }
        else this.service=null;

        let stage: AddVindicationState="tax";
        let value: VindicationRegisterData = {
            ...VindicationRegisterDataInitial,
            costs: true, interest: true,
            contacts: null,
            // contacts: [ emptyContact() ],
            emails: [ emptyCheckedContact() ],
            phones: [ emptyCheckedContact() ],
        };
        if(props.client && Array.isArray(props.client.invoices) && props.client.invoices.length>0) {
            // Na podstawie danych kontrahenta
            stage="documentsMonitored";
            const c: MonitoredClientDetails=props.client.client;
            // Dane kontrahenta do windykacji
            value.clientId=c.id;
            value.city=c.city;
            value.address=c.address;
            value.tax=c.tax;
            value.country="PL";
            value.email=c.email || "";
            value.phone=c.phone || "";
            value.name=c.name;
            value.postalCode=c.postalCode;

            // Mapowanie faktur, aby były po ID
            this.clientInvoices=new Map<String, MonitoredInvoice>();
            for(const i of props.client.invoices) this.clientInvoices.set(i.id, i);
        }

        this.state = {
            refreshId: 0,
            country: null,
            stage, //: "tax",
            autoCompany: false,
            value,
            files: [],
            regId: null,
            others: null,
            docsFromFile: false,
            docsFile: null,
            importErrors: null,
            importFileName: null,
            debtsAmicable: false,
            debtsJudicial: false,
            debtsDomestic: true,
            debtsTouched: false,
            newDebtTab: "new",
            debtsInsurer: null,
        }
    }

    componentDidMount() {
        this.props.serverApi.getCountry().then(country => this.setState({ country }));
        this.props.serverApi.getDictionaries().then((d: Dictionaries) => {
            const { documentTypes, insurers, currencies } = d;
            let fv=documentTypes.find((i: LangOption) => i.label.pl==="Faktura VAT" || i.label.pl==='Faktura');
            if(!fv) fv=documentTypes[0];
            this.docInvoice=fv;
            this.docOther=documentTypes.find((i: LangOption) => i.label.pl==="Inny" || i.label.pl==='Inne');
            if(!this.docOther) this.docOther=documentTypes[documentTypes.length-1];

            this.setState({
                documentTypes,
                defaultDocumentType: fv.value,
                // insurers,    // jest niżej
                currencies,
                globalCountries: d.globalCountries
            });
        })
        this.props.serverApi.getInsurers().then((insurers: Array<LangOption>) => {
            this.setState({
                insurers,
                debtsInsurer: (Array.isArray(insurers) && insurers.length>0)?insurers[0].value:null,
            })
        });
    }
    
    findDocumentType(value: string): LangOption {
        let type;
        if(value===null || value===undefined || value==="" || value==="Faktura/Invoice") type=this.docInvoice;
        else type=this.state.documentTypes.find((d: LangOption) => d.label.en===value || d.label.pl===value || value===d.label.pl+'/'+d.label.en);
        if(!type) type=this.docOther;
        return type;
    }

    async processCompany(tax: string) {
        const country = tax.substr(0, 2), value = tax.substr(2);
        let company = await this.props.serverApi.getCompanyInfo(country, value);
        if(company) {
            // console.log("Company info: ", company);
            this.setState({
                regId: null,
                autoCompany: true,
                validateDocuments: false,
                value: {
                    ...this.state.value,
                    tax: tax,
                    documents: [ emptyDocument(this.state.defaultDocumentType) ],
                    name: company.name,
                    postalCode: company.postalCode,
                    city: company.city,
                    address: company.address,
                    country: company.country,
                },
                stage: "company",
            })
        } else {
            this.setState({
                regId: null,
                value: {
                    ...this.state.value,
                    tax: tax,
                    name: "",
                    postalCode: "",
                    city: "",
                    address: "",
                    country: country || this.state.country || "",
                },
                stage: "company"
            })
        }
    }

    /** Etap ustawiania numeru NIP */
    renderStageNIP() {
        const nipForm=(formik: FormikBag) => <>
            <Form.Group name="tax">
                <Form.Label>{msgs.gui.labelDebtorNip}</Form.Label>
                <Form.NIP/>
            </Form.Group>
            <BForm.Row>
                <Button variant="link" className="mx-auto"
                        onClick={() => this.setState({
                            stage: "company",
                            value: {
                                ...this.state.value,
                                tax: null,
                                name: "",
                                postalCode: "",
                                city: "",
                                address: "",
                                country: this.state.country,
                            }
                        })}
                >{msgs.gui.labelNoDebtorNIP}</Button>
            </BForm.Row>
        </>;

        return this.props.children({
            title: msgs.gui.titleNewDebt,
            stage: this.state.stage,
            handleBack: this.props.onCancel?this.props.onCancel:null,
            handleNext: (e) => {
                if(this.state.newDebtTab==="new") {
                    this.formik.current.handleSubmit(e)
                } else {
                    this.handleMultipleNext();
                }
            },
            render: () => {
                const debtsError=(this.state.debtsTouched && (!this.state.debtsJudicial && !this.state.debtsAmicable))?msgs.validation.requiredAtLeastOne:null;
                return <Form
                    key="stage_tax"
                    initialValues={this.state.value} formikRef={this.formik}
                    validate={StageNIPValidator}
                    onSubmit={async (values, formik) => {
                        controlWaitGlass(true);
                        try {
                            const tax = values.tax;
                            if (tax.length <= 2) {
                                formik.setErrors({tax: msgs.validation.notEmpty});
                                return;
                            }
                            // dla zalogowanego użytkownika, sprawdzamy czy nie ma innych spraw dla tego dłużnika
                            if (this.props.logged) {
                                const other = await this.props.serverApi.getVindicationsForDebtor(tax);
                                if (Array.isArray(other) && other.length > 0) {
                                    this.setState({
                                        value: {
                                            ...values,
                                        },
                                        others: other,
                                        stage: "addToExisting"
                                    });
                                    return;
                                }
                            }

                            await this.processCompany(tax);
                        } finally {
                            controlWaitGlass(false);
                        }
                    }}
                >{(formik) => this.props.logged ? <Tabs
                    id="new-debt-single"
                    onSelect={(tab) => this.setState({ newDebtTab: tab })}
                    activeKey={this.state.newDebtTab}
                >
                    <Tab eventKey="new" title={msgs.gui.tabOneDebtor} className="pt-2">
                        {nipForm(formik)}
                    </Tab>
                    <Tab eventKey="new_import" title={msgs.gui.tabMultipleDebtors} className="pt-2">
                        <Form.Row className="align-items-center">
                            <Col md={4} className="form-group"><BForm.Label
                                className="col-form-label">{msgs.gui.labelDebtMode}</BForm.Label></Col>
                            <Col md={4} className="form-group">
                                <BForm.Check
                                    inline custom type="radio" id="vindications_domestic"
                                    label={msgs.gui.labelVindicationDomestic}
                                    checked={this.state.debtsDomestic}
                                    onChange={() => this.setState({debtsDomestic: true})}
                                />
                            </Col>
                            <Col md={4} className="form-group">
                                <BForm.Check
                                    inline custom type="radio" id="vindication_sforeign"
                                    label={msgs.gui.labelVindicationForeign}
                                    checked={!this.state.debtsDomestic}
                                    onChange={() => this.setState({debtsDomestic: false})}
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row className="align-items-center">
                            <Col md={4} className="form-group"><BForm.Label
                                className="col-form-label">{msgs.gui.labelDebtType}</BForm.Label></Col>
                            <Col md={4} className="form-group">
                                <BForm.Check
                                    custom type="checkbox" id="vindications_amicable"
                                    label={msgs.gui.debtTypeAmicable}
                                    checked={this.state.debtsAmicable}
                                    isInvalid={!!debtsError}
                                    feedback={debtsError}
                                    onChange={() => this.setState({debtsAmicable: !this.state.debtsAmicable})}
                                />
                            </Col>
                            <Col md={4} className="form-group">
                                <BForm.Check
                                    custom type="checkbox" id="vindications_judicial"
                                    label={msgs.gui.debtTypeJudicial}
                                    checked={this.state.debtsJudicial}
                                    isInvalid={!!debtsError}
                                    feedback={debtsError}
                                    onChange={() => this.setState({debtsJudicial: !this.state.debtsJudicial})}
                                />
                            </Col>
                        </Form.Row>
                        {(this.state.insurers.length>1) && <Form.RowGroup name="insurer">
                            <Col md={4} className="form-group">
                                <BForm.Label className="col-form-label">{msgs.gui.labelInsurerName}</BForm.Label>
                            </Col>
                            <Col md={8}>
                                <BForm.Control
                                    as="select"
                                    value={this.state.debtsInsurer}
                                    onChange={e => this.setState({  debtsInsurer: e.target.value })}
                                >
                                    {this.state.insurers.map((o: Option) => <option value={o.value} key={o.value}>{getLangValue(o.label)}</option>)}
                                </BForm.Control>
                            </Col>
                        </Form.RowGroup>}
                        <IconAlert className="mb-4">{this.props.displayInterestRate?msgs.gui.hintNewDebtsUploadIR:msgs.gui.hintNewDebtsUpload}</IconAlert>
                        <DefaultDropzone
                            onDrop={this.handleDropMultiple}
                            multiple={false}
                            className={(this.state.debtsTouched && !this.state.importFileName)?"dropzone-danger":null}
                            accept={NetworkDataProcessor.acceptData}
                            maxSize={this.props.maxSize}
                        >
                            {!this.state.importFileName?(this.state.debtsTouched?<p className="text-danger">{msgs.validation.required}</p>:null):<div className="file-info">
                                <span>{this.state.importFileName}</span>
                                {Array.isArray(this.state.debts)?<span>{this.state.debts.length}</span>:null}
                            </div>}
                        </DefaultDropzone>
                        {!this.state.importErrors ? null : <Alert variant="danger" className="mt-4">
                            <h4>{formatString(msgs.gui.importInvalid, this.state.importFileName)}</h4>
                            <ul>
                                {this.state.importErrors.map((er, index) => <li key={index}>
                                    <b>{formatString(msgs.gui.importInvalidRow, er.row + 2)}</b> <span>{er.errors.join(", ")}</span>
                                </li>)}
                            </ul>
                        </Alert>}
                    </Tab>
                </Tabs> : nipForm(formik)}
                </Form>
            }
        });
    }

    /** Gdy są inne sprawy, to tu dajemy możliwość podpięcia się do nich */
    renderStageAddToExisting() {
        return this.props.children({
            title: msgs.gui.titleCheckUploadedData,
            store: this.state.stage,
            handleBack: () => this.setState({ stage: "tax", others: null, regId: null, }),
            handleNext: null,
            render: () => <>
                <IconAlert>
                    <h1>{msgs.gui.labelSelectDebtInfo}</h1>
                    <p>{msgs.gui.hintSelectDebtInfo}</p>
                </IconAlert>
                <div className="others">
                    {this.state.others.map((d: VindicationTableInfo) => <Alert
                        key={d.id}
                        variant="success"
                        className="service-select"
                        onClick={() => {
                            this.setState({
                                regId: d.id,
                                regFullId: d.fullId,
                                stage: "documents",
                                value: {
                                    ...this.state.value,
                                    documents: [ emptyDocument(this.state.defaultDocumentType) ],
                                }
                            });
                        }}
                    >
                        <div>
                            <h1>{formatString(msgs.gui.actionAddToDebt, d.fullId)}</h1>
                            <div>{formatString(msgs.gui.hintAddToDebt,
                                d.extId || d.fullId,
                                (d.judicial && d.amicable)?msgs.gui.labelAmicableJudicial:(d.judicial?msgs.gui.labelJudicial:(d.amicable?msgs.gui.labelAmicable:"")),
                                formatDate(d.added),
                                formatMoney(d.actual, d.currency)
                            )}</div>
                        </div>
                        <span className="icon-angle-right"/>
                    </Alert>)}
                    <Alert
                        variant="success"
                        className="service-select"
                        onClick={async () => {
                            controlWaitGlass(true);
                            try {
                                await this.processCompany(this.state.value.tax);
                            }finally {
                                controlWaitGlass(false);
                            }
                        }}
                    >
                        <div>
                            <h1>{msgs.gui.actionNewVindication}</h1>
                        </div>
                        <span className="icon-angle-right"/>
                    </Alert>
                </div>
            </>
        });
    }

    /** Etap ustawiania danych firmy */
    renderStageCompany() {
        return this.props.children({
            title: msgs.gui.titleDebtorData,
            stage: this.state.stage,
            handleBack: () => this.setState({ stage: "tax" }),
            handleNext: (e) => this.formik.current.handleSubmit(e),
            render: () => <Form
                key="stage_company"
                initialValues={this.state.value} formikRef={this.formik}
                validate={StageCompanyValidator}
                onSubmit={async (values, formik) => {
                    this.setState({
                        autoCompany: false,
                        stage: "documents",
                        validateDocuments: false,
                        value: { documents: [ emptyDocument(this.state.defaultDocumentType) ], ...values }
                    })
                }}
            >{(formik) => <>
                <Form.Group name="name">
                    <Form.Label>{msgs.gui.labelDebtorName}</Form.Label>
                    <Form.Text/>
                </Form.Group>
                <Form.Row>
                    <Form.ColGroup name="postalCode" md={6}>
                        <Form.Label>{msgs.gui.labelPostalCode}</Form.Label>
                        <Form.Text/>
                    </Form.ColGroup>
                    <Form.ColGroup name="city" md={6}>
                        <Form.Label>{msgs.gui.labelCity}</Form.Label>
                        <Form.Text/>
                    </Form.ColGroup>
                </Form.Row>
                <Form.Row>
                    <Form.ColGroup name="address" md={6}>
                        <Form.Label>{msgs.gui.labelFullAddress}</Form.Label>
                        <Form.Text/>
                    </Form.ColGroup>
                    <Form.ColGroup name="country" md={6}>
                        <Form.Label>{msgs.gui.labelCountry}</Form.Label>
                        <Form.Country/>
                    </Form.ColGroup>
                </Form.Row>
            </>}</Form>
        })
    }

    /** Główna część formularza dodawania należności (310-4), tj. tabela */
    documentsTable() {
        let sum=new BigNumber("0");
        this.state.value.documents.forEach((d: VindicationDocument) => sum=addMoney(sum, d.amount));
        const validate=this.state.validateDocuments;

        return <div className="datatable new-vindication-documents">
            <div className="datatable-content">
                <Table>
                    <thead>
                    <tr>
                        <th className="input-type">{msgs.gui.labelDocumentType}</th>
                        <th>{msgs.gui.labelDocumentNr}</th>
                        <th className="input-money">{msgs.gui.labelAmount}</th>
                        <th className="input-date">{msgs.gui.labelSellDate}</th>
                        <th className="input-date">{msgs.gui.labelPayDate}</th>
                        <th className="delete"/>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.value.documents.map((doc: VindicationDocument, index: number) => {
                        const ti=index*10+1;
                        const update = (fields: $Shape<VindicationDocument>) => {
                            this.setState({
                                value: {
                                    ...this.state.value,
                                    documents: this.state.value.documents.map((i: VindicationDocument, index2: number) => {
                                        if (index2 === index) return {...doc, ...fields};
                                        else return i;
                                    })
                                }
                            })
                        };
                        const typeError=validate && !doc.type;
                        const numberError=validate && (!doc.number || doc.errorNumber);
                        const amountError=validate && !doc.amount;
                        const sellError=validate && !doc.sell;
                        const paymentError=validate && (!doc.payment || !isValidPaymentDate(doc.type, doc.sell, doc.payment));

                        const hasError=typeError || numberError || amountError || sellError ||paymentError;

                        return <tr key={index} className={cn(hasError && "error-row")}>
                            <td><DocumentType
                                types={this.state.documentTypes}
                                value={doc.type}
                                error={typeError}
                                onChange={(type) => update({type})}
                                tabIndex={ti}
                            /></td>
                            <td><DocumentNumber
                                value={doc.number}
                                error={numberError}
                                errorMessage={doc.errorNumber?"hintDocumentExists":undefined}
                                onChange={(number) => update({number})}
                                tabIndex={ti+1}
                            /></td>
                            <td><AmountInput
                                value={doc.amount}
                                error={amountError}
                                onChange={(amount) => update({amount})}
                                tabIndex={ti+2}
                            /></td>
                            <td><DatePicker
                                value={doc.sell}
                                otherValue={doc.payment}
                                error={sellError}
                                onChange={(sell) => update({sell})}
                                tabIndex={ti+3}
                                popperPlacement="bottom-start"
                            /></td>
                            <td><DatePicker
                                value={doc.payment}
                                otherValue={doc.sell}
                                error={paymentError}
                                onChange={(payment) => update({payment})}
                                tabIndex={ti+4}
                                popperPlacement="bottom-start"
                            /></td>
                            <td>{this.state.value.documents.length>1?<DeleteButton
                                tabIndex={ti+5}
                                onClick={() => {
                                    this.setState({
                                        value: {
                                            ...this.state.value,
                                            documents: this.state.value.documents.filter((i: VindicationDocument, index2: number) => index2 !== index)
                                        }
                                    })
                                }}
                            />:null}
                            </td>
                        </tr>
                    })}
                    <tr>
                        <td colSpan={6}>
                            <Button variant="link" tabIndex={49999} onClick={() => this.setState({
                                value: {...this.state.value, documents: [...this.state.value.documents, emptyDocument(this.state.defaultDocumentType) ]}
                            })}><span className="icon">+</span> {msgs.gui.actionAddMore}</Button>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </div>
            <div className="table-footer text-right">
                <h4 className="mr-3">{msgs.gui.labelSum}: <span className="money-value">{formatMoney(sum)}</span></h4>
            </div>
        </div>;
    }

    /**
     * Aktualizacja dokumentów na podstawie zaznaczania na liście.
     * @param rows zaznaczone elementy
     */
    handleInvoicesSelectionChange = (rows: DataTableRowSelection) => {
        // Konwersja typu danych z monitorowanych faktur do windykacji faktur
        let currency=null;
        let currencyValid=true;
        // console.log("Selection: ", { rows, invoices: this.clientInvoices });
        const documents: Array<VindicationDocument>=Object.keys(rows).map(id => {
            const mi=this.clientInvoices.get(id);
            if(!currency) currency=mi.currency;
            else if(currency!==mi.currency) currencyValid=false;    // różne waluty dla faktury
            return ({
                amount: mi.left,    // Czy to właściwa wartość?
                number: mi.number,
                payment: mi.payDate,
                sell: mi.sellDate,
                invoiceId: mi.id,
                type: this.docInvoice.value,
            }: VindicationDocument);
        });

        this.setState({
            value: {
                ...this.state.value,
                currency: currency,
                documents
            },
            validateDocuments: !currencyValid,
            refreshId: this.state.refreshId+(!currencyValid?1:0),
        })
    }

    /** Ekran z listą dokumentów monitorowanych */
    renderStageDocumentsMonitored() {
        let nextMessage=false;
        if(this.state.validateDocuments) {
            if(this.state.value.documents.length===0) {
                nextMessage= {
                    id: this.state.refreshId,
                    message: msgs.gui.imNewErrorSelectAny
                }
            } else {
                nextMessage = {
                    id: this.state.refreshId,
                    message: msgs.gui.importInvalidDifferentCurrency
                }
            }
        }

        return this.props.children({
            title: msgs.gui.titleNewDebts,
            stage: this.state.stage,
            handleBack: this.props.onCancel?this.props.onCancel:null,
            backLabel: msgs.gui.buttonCancel,
            nextMessage: nextMessage,
            handleNext: () => {
                // TODO: Jakaś walidacja.
                if(this.state.value.documents.length===0) {
                    this.setState({
                        refreshId: this.state.refreshId+1,
                        validateDocuments: true,
                    })
                    return;
                }

                this.setState({
                    stage: "params",
                    validateDocuments: false,
                    files: [],
                    value: {
                        ...this.state.value,
                        domestic: this.state.value.country === 'PL',
                        files: [],
                    }
                })
            },
            render: () => <>
                <ClientInvoicesList
                    displayClient={false}
                    actions={false}
                    data={this.props.client.invoices}
                    onSelectionChange={this.handleInvoicesSelectionChange}
                />
            </>
        })
    }

    /** Etap dodawania należności */
    renderStageDocuments() {
        return this.props.children({
            title: msgs.gui.titleNewDebts,
            stage: this.state.stage,
            nextMessage: this.state.validateDocuments && { id: this.state.refreshId, message: msgs.validation.invalidDataLong },
            handleBack: (e) => this.setState({
                stage: this.state.regId?"addToExisting": this.state.autoCompany ? "tax" : "company"
            }),
            handleNext: (e) => {
                if(!this.props.logged) {
                    if(this.state.docsFromFile) {
                        if(this.state.docsFile) {
                            this.setState({
                                stage: "params",
                                validateDocuments: false,
                                files: [],
                                value: {
                                    ...this.state.value,
                                    currency: currentLang.code==="pl"?"PLN":null,
                                    domestic: this.state.value.country === 'PL',
                                    files: [],
                                }
                            })
                        }
                        return; // bez pliku nie można kontynuować
                    }
                }

                const documents=this.state.value.documents;
                if(documents.length===0) return;
                // Uwaga walidacja jest przy imporcie wielu także oraz po stronie serwera, należy tego pilnować.
                let errors=[];
                documents.forEach((d: VindicationDocument, index) => {
                    const invalid=!d.type || !d.number || !d.amount ||
                        !d.sell || !d.payment || !isValidPaymentDate(d.type, d.sell, d.payment);
                    if(invalid) errors.push(index);
                })
                let valid=errors.length===0;

                const process=(valid) => {
                    if(!valid) {
                        this.setState({
                            validateDocuments: true,
                            refreshId: this.state.refreshId+1,
                        });
                    } else {
                        this.setState({
                            stage: "params",
                            validateDocuments: false,
                            files: [],
                            value: {
                                ...this.state.value,
                                documents: this.state.value.documents.map(({ errorNumber, ...d }: VindicationDocument) => d),
                                currency: currentLang.code==="pl"?"PLN":null,
                                domestic: this.state.value.country === 'PL',
                                files: [],
                            }
                        });
                    }
                }

                if(this.props.serverApi.checkDocuments) {
                    this.props.serverApi.checkDocuments(
                        documents
                            .filter((d: VindicationDocument) => d.type===this.docInvoice.value) // tylko faktury
                            .map((d: VindicationDocument) => ({             // korygujemy strukturę
                            number: d.number, pay: d.payment, sell: d.sell
                        }))
                    ).then(res => {
                        if(Array.isArray(res) && res.length>0) {
                            // console.log("Got duplicates: ", res);
                            process(false);
                            let errors=new Set(res);
                            this.setState({
                                value: {
                                    ...this.state.value,
                                    documents: this.state.value.documents.map((d: VindicationDocument) => {
                                        if(errors.has(d.number)) return {...d, errorNumber: true  };
                                        return d;
                                    })
                                }
                            })
                        } else {
                            process(valid);
                        }
                    });
                } else {
                    process(valid);
                }


            },
            render: () => this.props.logged?<>
                {this.documentsTable()}
                <div className="text-center mt-5">
                    <Button
                        variant="link"
                        onClick={(e) => this.setState({ stage: "documentsFromFile" })}
                    >{msgs.gui.actionAddFromCSV}</Button>
                </div>
            </>:<>
                <BForm.Check
                    className="mb-3"
                    type="radio" custom label={msgs.gui.vindicationReceivablesFromDocs} id="docs-table"
                    checked={!this.state.docsFromFile} onChange={() => this.setState({ docsFromFile: false })}
                />
                {this.state.docsFromFile?null:this.documentsTable()}
                <BForm.Check
                    className="mb-3 mt-4"
                    type="radio"  custom label={msgs.gui.vindicationReceivablesFromFile} id="docs-file"
                    checked={this.state.docsFromFile} onChange={() => this.setState({ docsFromFile: true })}
                />
                {!this.state.docsFromFile?null:<>
                    <IconAlert>{msgs.gui.hintReceivablesFromFile}</IconAlert>
                    <SingleFileInput
                        preview={false} info={true}
                        value={this.state.docsFile} onChange={(file) => this.setState({ docsFile: file })}
                        maxSize={this.props.maxSize}
                    />

                </>}
            </>
        })
    }

    /** Metoda importująca dokumenty na podstawie danych z pliku */
    handleImportDocs = (file: FileList) => {
        if(file.length!==1) return;
        let importer=new NetworkDataProcessor("docs", "receivables");
        importer.upload(file[0]).then((res: ImportResult) => {
            // console.log("Load res: ", res);
            let docs:Array<VindicationDocument>=this.state.value.documents || [];
            docs=docs.filter(d => d.amount || d.payment || d.sell || d.number);   // usuwamy puste
            let added=false;
            res.data.forEach((i: { Amount: string, Currency: string, DocumentType: string, Number: string, PaymentDate: string, SellDate: string }) => {
                let type=this.findDocumentType(i.DocumentType);

                if(!i.Amount && !i.DocumentType && !i.Number && !i.PaymentDate && !i.SellDate) return;
                docs.push({
                    type: type.value,
                    number: i.Number || "",
                    amount: i.Amount || "",
                    sell: i.SellDate || "",
                    payment: i.PaymentDate || ""
                });
                added=true;
            })
            this.setState({
                stage: "documents",
                value: {
                    ...this.state.value,
                    documents: docs
                }
            });

        }).catch(err => {
            console.log("Load err: ", err);
        })
    }

    /** Pomocniczy etap dodawania dokumentów z pliku */
    renderStageDocumentsFromFile() {
        return this.props.children({
            title: msgs.gui.titleNewDebts,
            stage: this.state.stage,
            handleBack: (e) => this.setState({stage: "documents"}),
            handleNext: null,
            render: () => <>
                <IconAlert children={msgs.gui.hintAddFromCSV} className="mb-4"/>
                <DefaultDropzone
                    onDrop={this.handleImportDocs}
                    multiple={false}
                    accept={NetworkDataProcessor.acceptData}
                    maxSize={this.props.maxSize}
                />
            </>
        });
    }

    handleOnDrop = (files: FileList) => {
        for(let i=0;i<files.length;++i) {
            const f=files[i];
            upload(f, String("vf_"+(++AddVindication.idGen)), (info: UploadInfo) => {
                if(info.state==="New") {
                    let vf: VindicationFile = {
                        dbId: null,
                        customName: "",
                    }
                    this.setState({
                        files: [...this.state.files, { upload: info, meta: vf } ],
                        value: {...this.state.value,
                            files: [...this.state.value.files, vf  ]
                        }
                    });
                } else {
                    this.setState({
                        files: this.state.files.map((i: FileInfo) => {
                            if(i.upload.id===info.id) {
                                if(info.state==="Done") i.meta.dbId=info.result.id;
                                return { ...i, upload: info };
                            }
                            return i;
                        })
                    });

                }
            }).finally();
        }
    }

    renderFile(f: FileInfo, index: number) {
        return <BForm.Row key={f.upload.id}>
            <Col className="preview">
                <i className={f.upload.result?mimeTypeToIcon(f.upload.result.mimeType):"fas fa-spinner"}/>
            </Col>
            <Col>
                <BForm.Group>
                    <BForm.Label>{f.upload.filename}</BForm.Label>
                    <InputGroup>
                        <BForm.Control
                            type="text"
                            placeholder={msgs.gui.labelNameThisFile}
                            value={f.meta.customName}
                            onChange={(e) => {
                                f.meta.customName=e.target.value;
                                this.forceUpdate();
                            }}
                        />
                        <InputGroup.Append>
                            <DeleteButton
                                disabled={!f.upload.result}
                                onClick={() => {
                                    this.setState({
                                        files: this.state.files.filter((i: FileInfo, mi) => index!==mi),
                                        value: {
                                            ...this.state.value,
                                            files: this.state.value.files.filter((i: VindicationFile) => i!==f.meta)
                                        }
                                    }, () => {
                                        deleteUpload(f.upload.result).finally();
                                    });
                                }}
                            />
                        </InputGroup.Append>
                    </InputGroup>
                </BForm.Group>
                <ProgressBar
                    variant={!f.upload.result?"primary":"success"}
                    now={f.upload.progress}
                    max={100}
                />
            </Col>
        </BForm.Row>;
    }

    /** Pomocnicza funkcja wyświetlająca informacje o kosztach */
    pricesInfo(country: string): React$Node {
        if(country==='PL') {
            return <IconAlert className="mt-5">{formatString(msgs.gui.hintNewDebtPrices, getDisplayLink(getLangValue(this.service.prices)))}</IconAlert>
        } else if(this.global && this.state.globalCountries.includes(country)) {
            return <IconAlert className="mt-5">{formatString(msgs.gui.hintNewDebtGlobalPrices, getDisplayLink(getLangValue(this.global.prices)))}</IconAlert>;
        } else {
            return <IconAlert className="mt-5">{msgs.gui.hintNewDebtOtherPrices}</IconAlert>;
        }
    }

    renderStageParams() {
        return this.props.children({
            title: msgs.gui.titleNewDebtsParams,
            stage: this.state.stage,
            handleBack: (e) => {
                this.setState({
                    stage: this.props.client?"documentsMonitored":"documents"
                })
            },
            nextLabel: msgs.gui.actionOrder,
            handleNext: (e) => {
                if(!this.state.regId) {
                    this.formik.current.handleSubmit(e)
                    return;
                }
                let data={
                    ...this.state.value,
                    vindicationId: this.state.regId,
                    files: this.state.value.files
                }
                this.props.serverApi.registerVindication(data).then(res => {
                    console.log("Added vindication with res: ", res);

                    if(this.props.eventName) emitEvent(this.props.eventName);
                    this.setState({
                        value: {
                            ...data,
                            contacts: [ emptyContact() ],
                        },
                        regId: res[0],
                        regFullId: res[1],
                        stage: "contact",
                        regDate: new Date(),
                    });
                    if(typeof(AddVindication.background)==='function') AddVindication.background();
                });
            },
            render: () => <Form
                key="stage_params"
                readonly={this.state.regId!==null}
                initialValues={this.state.value}
                formikRef={this.formik}
                customValidation={(values: VindicationRegisterData) => {
                    let err={};
                    if(!values.judicial && !values.amicable) {
                        err.judicial="validation.requiredAtLeastOne";
                        err.amicable="validation.requiredAtLeastOne";
                    }
                    if(this.props.displayInterestRate && values.interest) {
                        if (!values.interestRate) {
                            err.interestRate = 'validation.notNull';
                        } else if (values.interestRate < 0) {
                            err.interestRate = 'validation.invalidData';
                        }
                    }
                    return err;
                }}
                onSubmit={async (values: VindicationRegisterData, formik) => {
                    let data={
                        ...values,
                        files: this.state.value.files
                    }
                    if(this.props.logged) {
                        let res=await this.props.serverApi.registerVindication(data);
                        // console.log("Registered vindication with res: ", res);
                        if(!Form.setError(formik, res)) {
                            this.setState({
                                value: {
                                    ...data,
                                    contacts: [ emptyContact() ],
                                },
                                regId: res[0],
                                regFullId: res[1],
                                stage: "contact",
                                regDate: new Date(),
                            })
                        }
                    } else {
                        this.setState({
                            stage: "register",
                            value: { ...data }
                        })
                    }
                }}
            >{(formik) => <>
                {this.service?this.pricesInfo(this.state.value.country):null}
                {(this.state.insurers.length>0) && <Form.RowGroup name="insurer">
                    <Form.NullableSelect
                        label={msgs.gui.labelDebtInsured}
                        checkboxClassName="col-form-label"
                    >{(api) => <>
                        <Col md={6} className="form-group">
                            {api.checkbox()}
                        </Col>
                        <Col md={6} className="form-group">
                            {api.select(getLangOptions(this.state.insurers))}
                        </Col>
                    </>}</Form.NullableSelect>
                </Form.RowGroup>}
                <Form.Row className="align-items-center">
                    <Col md={4} className="form-group"><BForm.Label className="col-form-label">{msgs.gui.labelDebtMode}</BForm.Label></Col>
                    <Col md={4} className="form-group">
                        <BForm.Check
                            inline custom type="radio" id="vindication_domestic"
                            label={msgs.gui.labelVindicationDomestic}
                            checked={formik.values.domestic}
                            disabled={this.state.regId!==null}
                            onChange={() => formik.setFieldValue("domestic", true)}
                        />
                        <BForm.Check
                            inline custom type="radio" id="vindication_foreign"
                            label={msgs.gui.labelVindicationForeign}
                            checked={!formik.values.domestic}
                            disabled={this.state.regId!==null}
                            onChange={() => formik.setFieldValue("domestic", false)}
                        />
                    </Col>
                    <Col md={4}>
                        <Form.Group as={Row} name="currency">
                            <Form.Label column sm={6}>{msgs.gui.labelDebtCurrency}</Form.Label>
                            <Col sm={6}>
                            <Form.Select readonly={!!this.props.client}>
                                <option></option>
                                {getLangOptions(this.state.currencies)}
                            </Form.Select>
                            </Col>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row className="align-items-center">
                    <Col md={4} className="form-group"><BForm.Label className="col-form-label">{msgs.gui.labelDebtType}</BForm.Label></Col>
                    <Form.ColGroup md={4} name="amicable">
                        <Form.Check>{msgs.gui.debtTypeAmicable}</Form.Check>
                    </Form.ColGroup>
                    <Form.ColGroup md={4} name="judicial">
                        <Form.Check>{msgs.gui.debtTypeJudicial}</Form.Check>
                    </Form.ColGroup>
                </Form.Row>
                <Form.Row className="align-items-center">
                    <Col md={4} className="form-group"><BForm.Label className="col-form-label">{msgs.gui.labelChargeDebtor}</BForm.Label></Col>
                    <Form.ColGroup md={4} name="interest">
                        <Form.Check>{msgs.gui.chargeInterest}</Form.Check>
                    </Form.ColGroup>
                    <Form.ColGroup md={4} name="costs">
                        <Form.Check>{msgs.gui.chargeCosts}</Form.Check>
                    </Form.ColGroup>
                </Form.Row>
                {this.props.displayInterestRate && <Form.Row className="align-items-center">
                    <Col md={4} className="form-group"><BForm.Label className="col-form-label">{msgs.gui.labelChangeInterestRate}</BForm.Label></Col>
                    <Form.ColGroup md={3} name="interestRate">
                        <Form.Number min={0} float disabled={!formik.values.interest}/>
                    </Form.ColGroup>
                </Form.Row>}
                <Form.RowGroup name="extId">
                    <Form.Label column md={4}>{msgs.gui.labelCustomDebtorId}</Form.Label>
                    <Col md={8}>
                        <Form.Text md={8}/>
                    </Col>
                </Form.RowGroup>
                <Form.Row className="align-items-center">
                    <Col md={4} className="form-group">
                        <BForm.Label className="col-form-label">Nazwa wierzyciela pierwotnego</BForm.Label>
                    </Col>
                    <Form.ColGroup md={8} name="creditorName">
                        <Form.Text/>
                    </Form.ColGroup>
                </Form.Row>
                <Form.Row className="align-items-center">
                    <Col md={4} className="form-group">
                        <BForm.Label className="col-form-label">Adres wierzyciela pierwotnego</BForm.Label>
                    </Col>
                    <Form.ColGroup md={5} name="creditorAddress">
                        <Form.Text fieldName="creditorAddress"/>
                    </Form.ColGroup>
                    <Form.ColGroup md={3} name="creditorCountry">
                        <Form.Country fieldName="creditorCountry" nullable/>
                    </Form.ColGroup>
                </Form.Row>
                <BForm.Row>
                    <h4>{msgs.gui.labelAdditionalDocuments}</h4>
                    <p className="text-info">{msgs.gui.hintAdditionalDocuments}</p>
                    <DefaultDropzone
                        onDrop={this.handleOnDrop}
                        noClick={true}
                        maxSize={this.props.maxSize}
                    />
                    <div className="files w-100">
                        {this.state.files.map((f: FileInfo, index) => this.renderFile(f, index))}
                    </div>
                </BForm.Row>
            </>}</Form>,
        })
    }

    handleDownloadAsPdf= () =>{
        if(this.props.logged) {
            this.props.serverApi.generateReport(this.state.regId).then(file => {
                if (file) {
                    window.location.href = getDownloadLink(file.safeId, file.name);
                }
            })
        } else {
            this.props.serverApi.getRegisteredReport().then(file => {
                if (file) {
                    window.location.href = getDownloadLink(file.safeId, file.name);
                }
            })
        }
    }

    renderStageContact() {
        return this.props.children({
            title: msgs.gui.titleNewDebtSummary,
            backLabel: msgs.gui.buttonCancel,
            stage: this.state.stage,
            handleBack: () => this.props.onCancel(),
            handleClose: (e) => this.formik.current.handleSubmit(e),
            handleNext: (e) => this.formik.current.handleSubmit(e),
            render: () => <Form
                key="stage_contact"
                initialValues={this.state.value}
                formikRef={this.formik}
                onSubmit={async (values, helper) => {
                    if(this.props.logged) {
                        console.log("UpdateVindication contacts for: ", this.state.regId);
                        let res=await this.props.serverApi.updateVindicationContacts(this.state.regId, values);
                        this.props.onSuccess();
                    } else {
                        // TODO: Trybu rejestracja w dodanie windykacji
                        let res=await this.props.serverApi.updateRegisterVindicationContacts(this.state.regId, values);
                        this.props.onSuccess();
                    }
                }}
            >{(formik) => <>
                <IconAlert variant="light" icon={<Icon.Send/>} iconClassName="icon big" className="mt-4">
                    <h1>{msgs.gui.labelNewDebtNotification}</h1>
                    <p>{msgs.gui.hintNewDebtNotification}</p>
                    <Table className="vindication-contacts">
                        <thead>
                        <tr>
                            <th className="w-50">{msgs.gui.labelDebtorEmail}</th>
                            <th className="w-50">{msgs.gui.labelDebtorPhone}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <FieldArray name="emails" render={(arrayHelpers) => [
                                    ...formik.values.emails.map((c: VindicationCheckedContact, index: number) => <div key={index}>
                                        <InputGroup>
                                            <FormControl value={c.value} onChange={(e) => arrayHelpers.replace(index, {value: e.target.value, checked: c.checked })}/>
                                            <InputGroup.Append>
                                                <InputGroup.Checkbox
                                                    id={`cb-em-${index}`}
                                                    checked={c.checked}
                                                    onChange={(e) => arrayHelpers.replace(index, {value: c.value, checked: e.target.checked })}
                                                />
                                                <InputGroup.Text className="hint">
                                                    <Hint>{msgs.gui.labelNewDebtNotificationCheckbox}</Hint>
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </div>),
                                    <Button variant="link" tabIndex={49999} onClick={() => arrayHelpers.push(emptyCheckedContact())}>
                                        <span className="icon">+</span> {msgs.gui.actionAddMore}
                                    </Button>
                                ]}/>
                            </td>
                            <td>
                                <FieldArray name="phones" render={(arrayHelpers) => [
                                    ...formik.values.phones.map((c: VindicationCheckedContact, index: number) => <div key={index}>
                                        <InputGroup>
                                            <FormControl value={c.value} onChange={(e) => arrayHelpers.replace(index, {value: e.target.value, checked: c.checked })}/>
                                            <InputGroup.Append>
                                                <InputGroup.Checkbox
                                                    id={`cb-ph-${index}`}
                                                    checked={c.checked}
                                                    onChange={(e) => arrayHelpers.replace(index, {value: c.value, checked: e.target.checked })}
                                                />
                                                <InputGroup.Text className="hint">
                                                    <Hint>{msgs.gui.labelNewDebtNotificationCheckbox}</Hint>
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </div>),
                                    <Button variant="link" tabIndex={49999} onClick={() => arrayHelpers.push(emptyCheckedContact())}>
                                        <span className="icon">+</span> {msgs.gui.actionAddMore}
                                    </Button>
                                ]}/>
                            </td>
                        </tr>
                        {/*<FieldArray name={"contacts"} render={(arrayHelpers) => {*/}
                        {/*    return [ ...formik.values.contacts.map((c: VindicationContact, index: number) => {*/}
                        {/*        const update = (fields: $Shape<VindicationContact>) => {*/}
                        {/*            formik.setFieldValue('contacts',*/}
                        {/*                formik.values.contacts.map((i: VindicationContact, index2: number) => {*/}
                        {/*                    if (index2 === index) return {...c, ...fields};*/}
                        {/*                    return i;*/}
                        {/*                })*/}
                        {/*            )*/}
                        {/*        }*/}

                        {/*        return <tr key={index}>*/}
                        {/*            <td>*/}
                        {/*                <InputGroup>*/}
                        {/*                    <FormControl value={c.email}*/}
                        {/*                               onChange={(e) => update({email: e.target.value})}/>*/}
                        {/*                    <InputGroup.Append>*/}
                        {/*                        <InputGroup.Checkbox*/}
                        {/*                            id={`cb-em-${index}`}*/}
                        {/*                            checked={c.emailSend}*/}
                        {/*                            onChange={(e) => update({emailSend: e.target.checked})}*/}
                        {/*                        />*/}
                        {/*                        <InputGroup.Text*/}
                        {/*                            onClick={e => document.getElementById(`cb-em-${index}`).click()}*/}
                        {/*                        >{msgs.gui.labelNewDebtNotificationCheckbox}</InputGroup.Text>*/}
                        {/*                    </InputGroup.Append>*/}
                        {/*                </InputGroup>*/}
                        {/*            </td>*/}
                        {/*            <td>*/}
                        {/*                <InputGroup>*/}
                        {/*                    <FormControl value={c.phone}*/}
                        {/*                                 onChange={(e) => update({phone: e.target.value})}/>*/}
                        {/*                    <InputGroup.Append>*/}
                        {/*                        <InputGroup.Checkbox*/}
                        {/*                            id={`cb-ph-${index}`}*/}
                        {/*                            checked={c.phoneSend}*/}
                        {/*                            onChange={(e) => update({phoneSend: e.target.checked})}*/}
                        {/*                        />*/}
                        {/*                        <InputGroup.Text*/}
                        {/*                            onClick={e => document.getElementById(`cb-ph-${index}`).click()}*/}
                        {/*                        >{msgs.gui.labelNewDebtNotificationCheckbox}</InputGroup.Text>*/}
                        {/*                    </InputGroup.Append>*/}
                        {/*                </InputGroup>*/}
                        {/*            </td>*/}
                        {/*        </tr>*/}
                        {/*    }),*/}
                        {/*        <tr key="new">*/}
                        {/*            <td colSpan={2}>*/}
                        {/*                <Button variant="link" onClick={() => arrayHelpers.push(emptyContact())}>*/}
                        {/*                    {msgs.gui.actionAddMore}*/}
                        {/*                </Button>*/}
                        {/*            </td>*/}
                        {/*        </tr>*/}
                        {/*    ];*/}
                        {/*}}/>*/}
                        </tbody>
                    </Table>
                </IconAlert>
                <IconAlert>
                    <h1>{msgs.gui.labelNewDebtSummary}</h1>
                    <p dangerouslySetInnerHTML={{ __html: msgs.gui.hintNewDebtSummary }}/>
                    <Form.RowGroup name="otherContacts">
                        <Form.TextArea/>
                    </Form.RowGroup>
                </IconAlert>
                <IconAlert variant="none" className="mt-4" iconClassName="icon" icon={<Icon.RoundOK/>}>
                    <h1>{msgs.gui.labelNewVindicationId} {(this.props.logged && this.props.viewLink)?<LangLink to={this.props.viewLink(this.state.regId)}>{this.state.regFullId}</LangLink>:this.state.regId}</h1>
                    <div className="left-right align-items-center">
                        <div>
                            <span>{msgs.gui.labelRegisteredDate}</span> {formatDateTime(this.state.regDate)}
                        </div>
                        <div>
                            {!(this.props.serverApi.getRegisteredReport || this.props.serverApi.generateReport)?null:
                            <Button variant="link" onClick={this.handleDownloadAsPdf}>
                                <span className="icon gradient-circle mr-2"><span className="icon-download2"/></span> {msgs.gui.labelNewDebtSummaryPDF}
                            </Button>}
                        </div>
                    </div>
                </IconAlert>
            </>}</Form>
        });
    }

    renderRegister() {
        return this.props.children(this.props.registerRender(this.state,
            (regId, regFullId) => this.setState({
                stage: "contact",
                value: {
                    ...this.state.value,
                    contacts: [ emptyContact() ],
                },
                regId: regId,
                regFullId: regFullId,
                regDate: new Date()
            })
        ));
    }

    handleMultipleNext = () => {
        this.setState({ debtsTouched: true });
        if(!this.state.debtsAmicable && !this.state.debtsJudicial) return;
        if(!Array.isArray(this.state.debts)) return;
        this.setState({
            stage: "multiple",
            debts: this.state.debts.map((d: VindicationRegisterData) => {
                let c: VindicationRegisterData={...d};
                if(typeof(c.amicable)!=='boolean') c.amicable=this.state.debtsAmicable;
                if(typeof(c.judicial)!=='boolean') c.judicial=this.state.debtsJudicial;
                if(typeof(c.domestic)!=='boolean') c.domestic=this.state.debtsDomestic;

                return c;
            })
        });

    }

    handleDropMultiple= (file: FileList) => {
        if(file.length!==1) return;

        let importer=new NetworkDataProcessor("docs", "debts");
        importer.upload(file[0]).then((res: ImportResult) => {
            console.log("Load res: ", res);
            let debts:Map<string, VindicationRegisterData>=new Map();
            let errors=new Map<number, Array<string>>();
            res.data.forEach((i: MultipleDebtsColumns, row) => {
                let rowErrors=[];
                const amount=parseMoney(i.Amount);
                let type=this.findDocumentType(i.DocumentType);

                // Domyślny dla kraju
                if(i.Country===null || i.Country===undefined || i.Country==="") i.Country="PL";
                // Uwaga walidacja jest przy imporcie pojedynczego także oraz po stronie serwera, należy tego pilnować.
                if(!i.DebtorName) rowErrors.push(msgs.gui.importInvalidDebtorName);
                if(!i.City) rowErrors.push(msgs.gui.importInvalidCity);
                if(!i.NIP) rowErrors.push(msgs.gui.importInvalidTax);
                if(!i.Address) rowErrors.push(msgs.gui.importInvalidAddress);
                if(!i.PostalCode) rowErrors.push(msgs.gui.importInvalidPostalCode);
                if(!amount) rowErrors.push(msgs.gui.importInvalidAmount);
                if(!i.Number) rowErrors.push(msgs.gui.importInvalidNumber);
                if(!i.PaymentDate) rowErrors.push(msgs.gui.importInvalidPayDate);
                if(!i.SellDate) rowErrors.push(msgs.gui.importInvalidSellDate);
                if(!type) rowErrors.push(msgs.gui.importInvalidDocumentType);
                if(!i.Currency) rowErrors.push(msgs.gui.importInvalidCurrency);
                if(!isValidPaymentDate(type, i.SellDate, i.PaymentDate)) rowErrors.push(msgs.gui.importInvalidSellPayDate);
                if(typeof(i.Country)!=='string' || i.Country.startsWith("!") || i.Country.length!==2) rowErrors.push(msgs.gui.importInvalidCountry);
                if(i.OriginalCreditorCountry && (typeof(i.OriginalCreditorCountry)!=='string' || i.OriginalCreditorCountry.startsWith("!") || i.OriginalCreditorCountry.length!==2)) rowErrors.push(msgs.gui.importInvalidOriginalCreditorCountry);
                if(this.props.displayInterestRate && i.ChargeInterests===true && !i.InterestRate) rowErrors.push(msgs.gui.importMissingInterestRate);
                if(i.Insured===true && !this.state.debtsInsurer) rowErrors.push(msgs.gui.importInvalidInsurer);

                if(rowErrors.length>0) {
                    errors.set(row, rowErrors);
                    return;
                }

                const id=i.NIP+"\t"+i.Currency;
                let debt: VindicationRegisterData & { sum: string }=debts.get(id);
                if(!debt) {
                    debt={
                        files: [],
                        documents: [],
                        currency: i.Currency,
                        extId: i.DebtorId,
                        name: i.DebtorName,
                        postalCode: i.PostalCode,
                        country: i.Country || "PL",
                        domestic: i.Domestic,
                        tax: i.NIP,
                        city: i.City,
                        address: i.Address,
                        interest: i.ChargeInterests,
                        interestRate: this.props.displayInterestRate?i.InterestRate:null,
                        costs: i.ChargeCosts,
                        amicable: null,
                        judicial: null,
                        insurer: i.Insured===true?this.state.debtsInsurer:null,
                        emails: i.Email?[{ value: i.Email, checked: typeof(i.EmailNotification)==='boolean'?i.EmailNotification:true }]:[],
                        phones: i.Phone?[{ value: i.Phone, checked: typeof(i.PhoneNotification)==='boolean'?i.PhoneNotification:true }]:[],
                        creditorName: i.OriginalCreditorName,
                        creditorAddress: i.OriginalCreditorAddress,
                        creditorCountry: i.OriginalCreditorCountry
                    }
                    debts.set(id, debt);
                } else {
                    // uzupełniamy z innych wierszy, jeżeli nie było
                    if(!debt.extId) debt.extId=i.DebtorId;
                    if(!debt.postalCode) debt.postalCode=i.PostalCode;
                    if(debt.domestic===null) debt.domestic=i.Domestic;
                    // if(!debt.tax) debt.tax=i.NIP;    // to jest klucz
                    if(!debt.city) debt.city=i.City;
                    if(!debt.address) debt.address=i.Address;
                    if(debt.interest===null) debt.interest=i.ChargeInterests;
                    if(debt.costs===null) debt.costs=i.ChargeCosts;
                    if(!debt.name) debt.name=i.DebtorName;
                    if((!debt.emails || debt.emails.length===0) && i.Email) debt.emails=[{ value: i.Email, checked: typeof(i.EmailNotification)==='boolean'?i.EmailNotification:true }];
                    if((!debt.phones || debt.phones.length===0) && i.Phone) debt.phones=[{ value: i.Phone, checked: typeof(i.PhoneNotification)==='boolean'?i.PhoneNotification:true }];
                    if(this.props.displayInterestRate) if(!debt.interest) debt.interestRate=i.InterestRate;

                    if(!debt.creditorAddress) debt.creditorAddress=i.OriginalCreditorAddress;
                    if(!debt.creditorCountry) debt.creditorCountry=i.OriginalCreditorCountry;
                    if(!debt.creditorName) debt.creditorName=i.OriginalCreditorName;

                    if(debt.currency!==i.Currency) {
                        errors.set(row, [msgs.gui.importInvalidDifferentCurrency]);
                        return;
                    }
                    if( (i.Insured===true && !debt.insurer) || (i.Insured===false && debt.insurer) ){
                        errors.set(row, [msgs.gui.importInvalidDifferentInsurer]);
                    }
                }

                debt.sum=addMoney(debt.sum, amount);

                debt.documents.push({
                    type: type.value,
                    number: i.Number || "",
                    amount: i.Amount || "",
                    sell: i.SellDate || "",
                    payment: i.PaymentDate || ""
                });
            });
            const flush=() => {
                if (errors.size > 0) {
                    this.setState({
                        importErrors: Array.from(errors, ([ row, errors ]) => ({ row: row, errors: errors })),
                        importFileName: file[0].name
                    });
                    return;
                }
                this.setState({
                    importErrors: null,
                    importFileName: file[0].name,
                    debts: Array.from(debts.values()).filter((i: VindicationRegisterData) => i.documents.length > 0)
                }, this.handleMultipleNext);
            };

            // Po zbudowaniu struktury wysyłamy dokumenty do weryfikacji
            if(this.props.serverApi.checkDocuments) {
                let docs=[];
                for(let debt:VindicationRegisterData of debts.values()) {
                    for (let d of debt.documents) {
                        if(d.type!==this.docInvoice.value) continue;  // tylko faktury
                        docs.push({
                            number: d.number, pay: d.payment, sell: d.sell
                        });
                    }
                }
                this.props.serverApi.checkDocuments(docs).then(bad_ => {
                    if(Array.isArray(bad_) && bad_.length>0) {
                        let bad=new Set(bad_);
                        res.data.forEach((i: MultipleDebtsColumns, row) => {
                            if(i.Number && bad.has(i.Number)) {
                                let type=this.findDocumentType(i.DocumentType);
                                if(type!==this.docInvoice) return;  // tylko faktury
                                let cur:Array<string>=errors.get(row);
                                if(!Array.isArray(cur)) errors.set(row, cur=[]);
                                cur.push(msgs.gui.hintDocumentExists);
                            }
                        });
                    }
                    flush();
                });
            } else flush();
        }).catch(err => {
            console.log("Load err: ", err);
        })
    }

    renderStageMultiple() {
        return this.props.children({
            title: msgs.gui.titleCheckUploadedData,
            stage: this.state.stage,
            handleBack: () => {
                this.setState({
                    stage: "tax"
                });
            },
            nextLabel: msgs.gui.actionOrderDebtCollection,
            handleNext: (e) => {
                this.props.serverApi.registerMultipleVindications(this.state.debts.map((d) => {
                    const { sum, ...rest } = d;
                    return rest;
                })).then(err => {
                    console.log("Result: ", err);
                    if(Validate.isError(err)) {
                        return;
                    }
                    if(this.props.eventName) emitEvent(this.props.eventName);
                    this.setState({
                        regId: null,
                        stage: "multiple_done",
                    })
                })
            },
            render: () => <>
                <IconAlert>{msgs.gui.hintCheckUploadedData}</IconAlert>
                <Table>
                    <thead>
                    <tr>
                        <td>{msgs.gui.labelDebtorName}</td>
                        <td>{msgs.gui.labelNIP}</td>
                        <td>{msgs.gui.labelDebtorAddress}</td>
                        <td>{msgs.gui.labelAmount}</td>
                        <td>{msgs.gui.labelAmountOfReceivables}</td>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.debts.map((d: VindicationRegisterData, index) => <tr key={index}>
                            <td>{d.name}</td>
                            <td>{d.tax}</td>
                            <td>{d.address}</td>
                            <td>{formatMoney(d.sum, d.currency)}</td>
                            <td>{d.documents.length}</td>
                        </tr>)}
                    </tbody>
                </Table>
            </>
        });
    }

    renderStageMultipleDone() {
        return this.props.children({
            title: msgs.gui.titleNewDebtSummary,
            stage: this.state.stage,
            handleBack: null,

            handleNext: () => this.props.onSuccess(),
            render: () => <>
                <IconAlert>{msgs.gui.importHint}</IconAlert>
            </>
        });
    }

    render() {
        if(!this.state.country || !this.state.documentTypes || !Array.isArray(this.state.insurers)) return null;
        switch(this.state.stage) {
            case "tax": return this.renderStageNIP();
            case "addToExisting": return this.renderStageAddToExisting();
            case "company": return this.renderStageCompany();
            case "documents": return this.renderStageDocuments();
            case "documentsFromFile": return this.renderStageDocumentsFromFile();
            case "documentsMonitored": return this.renderStageDocumentsMonitored();
            case "params": return this.renderStageParams();
            case "register": return this.renderRegister();
            case "contact": return this.renderStageContact();
            case "multiple": return this.renderStageMultiple();
            case "multiple_done": return this.renderStageMultipleDone();
        }
    }

}
AddVindication.contextType=LangContext;
